import styles from './RiskOverlay.module.scss';
import WindSpeedSlider from './WindSpeedSlider';
import { useTranslation } from 'react-i18next';
import { Tree } from '../../../../tree/Tree';
import { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentAccount } from '../../../../account/useAccounts';
import * as THREE from 'three';
import SafetyFactorMarkerGroup from '../twin-view/SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from '../../../../components/PointCloud/LeaningAngleGroup';
import { CentroidsGroup } from '../../../../components/PointCloud/CentroidsGroup';
import { LabelWithPointingLine } from '../../../../components/PointCloud/LabelWithPointingLine';
import DetailedTree from '../../../../tree/DetailedTree';
import { PanoramicViewLight } from '../../../Explore/panoramic-view/PanoramicViewLight';
import { cardinalDirectionToAngle } from '../../../../utils/getCardinalDirectionFromAngle';
import { Flippers } from '../../../../switches/Flippers';

enum SafetyFactorViewOptions {
  SafetyFactor = 'safetyFactor',
  LeaningAngle = 'leaningAngle',
  // Centroids = 'centroids',
  All = 'all'
}

export default function StreetViewRiskOverlay(props: StreetViewRiskOverlayProps) {
  const { tree } = props;
  const { t } = useTranslation();

  const account = useCurrentAccount();
  const prevailingWindDirection = tree?.prevailingWindDirection || account.organization.prevailingWindDirection;

  const [selectedProperty, setSelectedProperty] = useState<SafetyFactorViewOptions | null>(SafetyFactorViewOptions.SafetyFactor);
  const showSafetyFactor = selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.SafetyFactor;
  const showLeaningAngle = selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.LeaningAngle;
  const showCentroids = false; // selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.Centroids;
  const [windSpeed, setWindSpeed] = useState(account.getDefaultWindSpeed());
  const updateSelectedProperty = selectedProperty => {
    setSelectedProperty(selectedProperty);
  };

  const updateWindSpeed = (windSpeed: number) => {
    setWindSpeed(windSpeed);
  };

  const safetyFactorCircle = useMemo(() => {
    const scalingFactor = props.tree!.metricHeight / 6;
    return new SafetyFactorMarkerGroup(2, scalingFactor * 0.025, 50, cardinalDirectionToAngle(prevailingWindDirection), scalingFactor * 0.5);
  }, [props.tree, prevailingWindDirection]);

  const leaningAngleGroupRef = useRef<LeaningAngleGroup | null>(null);
  const centroidsRef = useRef<CentroidsGroup | null>(null);
  const windDirectionMarker = useRef<LabelWithPointingLine | null>(null);

  useEffect(() => {
    if (!props.viewRef.current || tree === null) return;
    props.viewRef.current?.clear2DRenderer();
    if (leaningAngleGroupRef.current) props.viewRef.current.removeFromScene(leaningAngleGroupRef.current);
    if (centroidsRef.current) props.viewRef.current.removeFromScene(centroidsRef.current);
    leaningAngleGroupRef.current = new LeaningAngleGroup(
      props.tree?.metricHeight || 0,
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
      t('analytics.properties.leaningAngle'),
      () => {},
      false
    );
    centroidsRef.current = new CentroidsGroup(props.tree);

    if (showCentroids) {
      props.viewRef.current.addToScene(centroidsRef.current);
    }
    if (showLeaningAngle) {
      props.viewRef.current.addToScene(leaningAngleGroupRef.current);
    }
    props.viewRef.current?.render();

    return () => {
      if (leaningAngleGroupRef.current) props.viewRef.current?.removeFromScene(leaningAngleGroupRef.current);
      if (centroidsRef.current) props.viewRef.current?.removeFromScene(centroidsRef.current);
    };
  }, [
    tree,
    t,
    selectedProperty,
    safetyFactorCircle
  ]);

  useEffect(() => {
    props.viewRef.current?.addToScene(safetyFactorCircle);
    windDirectionMarker.current = new LabelWithPointingLine(
      new THREE.Vector3(safetyFactorCircle?.arrow?.position.x || 0, 0, -(safetyFactorCircle?.arrow?.position.y || 0)),
      `${t('details.properties.prevailingWindDirection')}: ${t('treeDetails.risk.windDirections.' + prevailingWindDirection)}`,
      new THREE.Vector2(5, -2)
    );
    if (prevailingWindDirection) props.viewRef.current?.addToScene(windDirectionMarker.current);

    return () => {
      // safetyFactorCircle.clear();
      props.viewRef.current?.removeFromScene(safetyFactorCircle);
      if (windDirectionMarker.current) {
        windDirectionMarker.current?.clear();
        props.viewRef.current?.removeFromScene(windDirectionMarker.current);
      }
      props.viewRef.current?.clear2DRenderer();
      props.viewRef.current?.render();
    };
  }, [props.viewRef.current]);

  return (<div className={styles.streetViewRiskOverlayContainer}>
    <TreePropertyList>
      {Object.values(SafetyFactorViewOptions).map(it => (
        <PropertyListItem
          key={it}
          onClick={() => updateSelectedProperty(it)}
          active={selectedProperty === it}>
          {t('analytics.properties.' + it)}
        </PropertyListItem>
      ))}
    </TreePropertyList>
    {showCentroids &&
      <ul className={styles.centroidLegends}>
        <li><span className={styles.greenCircle} />{t('treeDetails.risk.crownCentroid')}</li>
        <li><span className={styles.cyanCircle} />{t('treeDetails.risk.firstBifurcation')}</li>
        <li><span className={styles.pinkCircle} />{t('treeDetails.risk.trunkBase')}</li>
      </ul>
    }
    {showSafetyFactor &&
      <div className={styles.badgeAndSliderContainer}>
        {(account.organization.isEnabled(Flippers.windspeedSlider) && tree.safetyFactors.length > 0) && (
          <WindSpeedSlider
            tree={tree}
            windSpeed={windSpeed}
            updateWindSpeed={updateWindSpeed}
          />
        )}
        <SafetyFactorBadge safetyFactor={tree.getSafetyFactor(windSpeed)} />
      </div>}
  </div>);
}

function SafetyFactorBadge({ safetyFactor }: { safetyFactor: number | null }) {
  const { t } = useTranslation();
  if (!safetyFactor) return <></>;

  const classes = [styles.safetyFactorBadge];
  if (safetyFactor < Tree.SAFETY_FACTOR_THRESHOLD) classes.push(styles.safetyFactorBadgeDanger);

  return (
    <div className={classes.join(' ')}>
      <span>{t('parameters.tsi')}</span>
      <span>{safetyFactor.toFixed(2)}</span>
    </div>
  );
}

function TreePropertyList({ children }: { children?: ReactNode }) {
  return <ul className={styles.propertyList}>{children}</ul>;
}

function PropertyListItem(props: PropertyListItemProps | DisabledPropertyListItemProps) {
  const className = styles.propertyListItemButton.concat(
    ' ',
    'active' in props && props.active ? styles.active : '',
    ' ',
    props.disabled ? styles.disabled : ''
  );

  const onClick = () => {
    if ('onClick' in props) {
      props.onClick();
    }
  };

  return (
    <li className={styles.propertyListItem}>
      <button onClick={onClick} className={className}>
        {props.children}
      </button>
    </li>
  );
}

interface PropertyListItemProps {
  children: ReactNode,
  onClick: () => unknown,
  active: boolean,
  disabled?: true
}

interface DisabledPropertyListItemProps {
  children: ReactNode,
  disabled: true
}

interface StreetViewRiskOverlayProps {
  tree: DetailedTree,
  viewRef: MutableRefObject<PanoramicViewLight | null>
}
