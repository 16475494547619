import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Auth from '../components/Auth/Auth';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Settings from './Settings/Settings';
import Home from './Home/Home';
import DefaultRoute from './DefaultRoute';
import { Signup } from './Signup/Signup';
import Explore from './Explore/Explore';
import TreeAnalytics from './Tree/Analytics';
import PrintableReport from './Explore/reports/PrintableReport';
import { DependencyInjectionContextProvider } from '../DependencyInjectionContext';
import SuperOwnerPage from './SuperOwnerPage/SuperOwnerPage';
import TranslationTool from './Translation/TranslationTool';
import { Suspense } from 'react';
import Spinner from '../components/UI/Spinner/Spinner';
import LegacyDetails from './LegacyDetails/LegacyDetails';
import Inventory from './Inventory/Inventory';
import TaskManager from './TaskManager/TaskManager';
import TaskManagerGuard from '../components/FeatureGuard/TaskManagerGuard';
import Details from './Details/Details';
import { SASTokenProvider } from '../components/Auth/SASTokenProvider';

const protectedRoutes = [
  { path: '/organizations/:organizationId/settings/*', component: Settings },
  { path: '/organizations/:organizationId/map', component: Explore },
  { path: '/organizations/:organizationId/inventory', component: Inventory },
  { path: '/organizations/:organizationId/inventory/trees/:treeId', component: Details }, //
  { path: '/organizations/:organizationId/remote-inspections/:taskId', component: Details },
  { path: '/organizations/:organizationId/map/report', component: PrintableReport },
  {
    path: '/organizations/:organizationId/home',
    component: Home
  },
  { path: '/organizations/:organizationId/trees/:treeId/analytics/*', component: TreeAnalytics },
  { path: '/organizations/:organizationId/trees/:treeId/details/*', component: LegacyDetails },
  { path: '/super-owner/*', component: SuperOwnerPage },
  { path: '/translation-tool/*', component: TranslationTool },
  { path: '/organizations/:organizationId/task-manager/:view', component: TaskManager }
];

export default function AppRouter() {
  return (
    <>
      <Router>
        <Suspense fallback={<Spinner />}>
          <DependencyInjectionContextProvider>
            <Routes>
              {protectedRoutes.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={<Auth>{() => <SASTokenProvider><Component /></SASTokenProvider>}</Auth>} />
              ))}

              <Route
                path="/organizations/:organizationId/task-manager/*"
                element={<Auth>{() =><SASTokenProvider><TaskManagerGuard><TaskManager /></TaskManagerGuard></SASTokenProvider>}</Auth>}
              >
                <Route path="create/*" element={<></>}/>
                <Route path="manage/*" element={<></>}/>
              </Route>

              <Route path="/forgot-password" element={<ForgotPassword />} />

              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/signup" element={<Signup />} />

              <Route path="*" element={<Auth>{() => <DefaultRoute />}</Auth>} />
            </Routes>
          </DependencyInjectionContextProvider>
        </Suspense>
      </Router>
    </>
  );
}
