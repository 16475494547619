import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Fork, Tree } from '../../../tree/Tree';
import { useCurrentAccount } from '../../../account/useAccounts';
import {
  AccordionDropdownField,
  AccordionGroup,
  AccordionMultiSelectField,
  AccordionNumericInputField,
  AccordionTextInputField
} from './AccordionGroup';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Item } from '../../../components/UI/Dropdown/Dropdown';
import { CardinalDirection } from '../../../utils/getCardinalDirectionFromAngle';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { LandUse } from '../../../property-enums/LandUse';
import { OverheadUtilities } from '../../../property-enums/OverheadUtilities';
import { GrowSpace } from '../../../property-enums/GrowSpace';
import NumberOfLimbs from './components/NumberOfLimbs';
import PotentialTargets from './components/PotentialTargets';
import { DetailsContext } from '../CarbonDetails';
import { ObservationItem } from './ObservationItem';
import { Flippers } from '../../../switches/Flippers';
import { Accordion, AccordionItem, Tag } from '@carbon/react';

export enum DataCollectionSections {
  AssignmentAndTreeId = 'assignmentAndTreeId',
  TargetAssessment = 'targetAssessment',
  SiteFactors = 'siteFactors',
  TreeHealthAndSpeciesProfile = 'treeHealthAndSpeciesProfile',
  LoadFactors = 'loadFactors',
  CrownAndBranches = 'crownAndBranches',
  Trunk = 'trunk',
  RootsAndRootCollar = 'rootsAndRootCollar'
}

const booleanItems: Item[] = [
  { id: 'true', translationKey: 'details.properties.booleanLabels.true' },
  { id: 'false', translationKey: 'details.properties.booleanLabels.false' }
];

interface Option<T> {
  label: string,
  value: T
}

export default function DataCollection({ tree, handleChange, editingSection, setEditingSection, isCaptureModeActive, setCaptureModeActive }: {
  tree: DetailedTree | null,
  handleChange: <T>(property: string, value: T) => void,
  editingSection: string | null,
  setEditingSection: Dispatch<SetStateAction<string | null>>,
  isCaptureModeActive: boolean,
  setCaptureModeActive: Dispatch<SetStateAction<boolean>>
}) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const [editingSubsection, setEditingSubsection] = useState<string | null>(null);
  const { setRootZoneVisibility } = useContext(DetailsContext);
  const [openAccordionItem, setOpenAccordionItem] = useState<string | null>(null);

  const handleToggle = (id: string) => {
    setOpenAccordionItem(prev => (prev === id ? null : id));
  };

  const prevailingWindDirectionOptions: Item[] = Object.values(CardinalDirection).map(direction => {
    return { id: direction, translationKey: 'treeDetails.risk.windDirections.' + direction };
  });
  const landUseOptions: Option<string>[] = Object.values(LandUse).map(land => {
    return { value: land, label: t('details.properties.landUseTypes.' + land) };
  });
  const overheadUtilitiesOptions: Item[] = Object.values(OverheadUtilities).map(utility => {
    return { id: utility, translationKey: t('details.properties.overheadUtilitiesTypes.' + utility) };
  });
  const growSpaceOptions: Item[] = Object.values(GrowSpace).map(space => {
    return { id: space, translationKey: 'details.properties.growSpaceTypes.' + space };
  });

  useEffect(() => {
    return () => {
      if (editingSection) {
        setEditingSection(null);
      }
      setRootZoneVisibility(false);
    };
  }, [tree?.id]);

  const getAccordionItemClassName = useCallback((label: string) => {
    return (openAccordionItem === label ? '[&>button]:text-[var(--cds-link-primary-hover)]' : '[&>button>div]:text-[var(--cds-text-secondary)]') +
      ' [&>button]:h-12 [&>button>div]:font-semibold' +
      ` ${openAccordionItem === label ? '!border-t-[var(--cds-interactive)] !border-b-[var(--cds-interactive)] !border-t-2 !border-b-2' : ''}`;
  }, [openAccordionItem]);

  const getAccordionTitleWithCounterBadge = (title: string, count?: number) => {
    if (!count) return title;
    return (
      <div className="flex justify-between items-center">
        {title}
        <Tag className="cursor-pointer" type="high-contrast">{count}</Tag>
      </div>
    );
  };

  return (
    <ul className="twp divide-y divide-outer-space-500">
      <Accordion>
        <AccordionItem
          title={t(`treeDetails.dataCollection.${DataCollectionSections.AssignmentAndTreeId}`)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.AssignmentAndTreeId}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.AssignmentAndTreeId}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.AssignmentAndTreeId}`)) + ' border-t-[var(--cds-layer-01)]'}
        >
          <div className="divide-y divide-[var(--cds-border-subtle-00)]">
            <AccordionTextInputField
              property={DisplayableTreeProperty.TmsCategory}
              value={tree?.tmsCategory ? String(tree.tmsCategory).charAt(0).toUpperCase() + String(tree.tmsCategory).slice(1) : null}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CustomerSiteId}
              value={tree?.customerSiteId}
              editable />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CustomerTagId}
              value={tree?.customerTagId}
              editable />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CustomerTreeId}
              value={tree?.customerTreeId}
              editable />
            <AccordionTextInputField property={DisplayableTreeProperty.Owner} value={tree?.owner} editable />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.PlantingYear}
              value={tree?.plantingYear}
              editable
              integer />
            <AccordionTextInputField property={DisplayableTreeProperty.AgeClass} value={tree?.ageClass} />
            {organization.isEnabled(Flippers.davey) ? '' : (
              <AccordionTextInputField
                property={DisplayableTreeProperty.LocalizedLocation}
                value={tree?.displayableWorldCoordinates} />
            )}
            <AccordionTextInputField
              property={DisplayableTreeProperty.StreetAddress}
              value={tree?.streetAddress}
              editable />
            <AccordionTextInputField
              property={DisplayableTreeProperty.AddressFromParcel}
              value={tree?.addressFromParcel}
              editable />
            <AccordionTextInputField
              property={DisplayableTreeProperty.SideLocation}
              value={tree?.sideLocation}
              editable />
            <AccordionTextInputField property={DisplayableTreeProperty.ParkName} value={tree?.parkName} editable />
            <AccordionTextInputField
              property={DisplayableTreeProperty.Genus}
              value={tree?.getGenus(organization)}
              editable
              italic
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.Species}
              value={tree?.getSpecies(organization)}
              editable
              italic
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.Cultivar}
              value={tree?.cultivar}
              editable
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.Infraspecies}
              value={tree?.infraspecies}
              editable
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CommonName}
              value={tree?.commonName}
              editable
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionNumericInputField property={DisplayableTreeProperty.Height} value={tree?.height} defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.NumberOfStems}
              value={tree?.numberOfStems}
              integer />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.TrunkCircumference}
              value={tree?.trunkCircumference}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.TrunkDiameter}
              value={tree?.trunkDiameter}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.TrunkHeight}
              value={tree?.trunkHeight}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.CanopyWidth}
              value={tree?.canopyWidth}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.CanopyHeight}
              value={tree?.canopyHeight}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.CanopyCircumference}
              value={tree?.canopyCircumference}
              defaultUnit />
            <AccordionNumericInputField
              property={DisplayableTreeProperty.CrownVolume}
              label={t('tree.crownVolume')}
              value={tree?.crownVolume}
              defaultUnit />
          </div>
        </AccordionItem>
        <AccordionItem
          title={getAccordionTitleWithCounterBadge(t(`treeDetails.dataCollection.${DataCollectionSections.TargetAssessment}`), tree?.potentialTargets?.length)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.TargetAssessment}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.TargetAssessment}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.TargetAssessment}`))}
        >
          <PotentialTargets
            tree={tree}
            editing={editingSection === DataCollectionSections.TargetAssessment}
            handleChange={handleChange}
          />
        </AccordionItem>
        <AccordionItem
          title={t(`treeDetails.dataCollection.${DataCollectionSections.SiteFactors}`)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.SiteFactors}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.SiteFactors}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.SiteFactors}`))}
        >
          <AccordionDropdownField items={prevailingWindDirectionOptions} property={'prevailingWindDirection' as DisplayableTreeProperty} value={prevailingWindDirectionOptions.find(it => it.id === tree?.prevailingWindDirection)}/>
          <AccordionMultiSelectField property={DisplayableTreeProperty.LandUse} value={tree?.landUse ?? []} options={landUseOptions}/>
          <AccordionDropdownField property={DisplayableTreeProperty.OverheadUtilities} value={overheadUtilitiesOptions.find(it => it.id === tree?.overheadUtilities)} items={overheadUtilitiesOptions}/>
          <AccordionTextInputField property={DisplayableTreeProperty.GrowSpaceSize} value={tree?.growSpaceSize} editable/>
          <AccordionDropdownField property={DisplayableTreeProperty.GrowSpace} value={growSpaceOptions.find(it => it.id === tree?.growSpace)} items={growSpaceOptions}/>
        </AccordionItem>
        <AccordionItem
          title={t(`treeDetails.dataCollection.${DataCollectionSections.TreeHealthAndSpeciesProfile}`)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.TreeHealthAndSpeciesProfile}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.TreeHealthAndSpeciesProfile}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.TreeHealthAndSpeciesProfile}`))}
        >
          <AccordionDropdownField
            items={Object.values(TreeStatus).map(it => ({ id: it, translationKey: `tree.statusTypes.${it}` }))}
            property={DisplayableTreeProperty.Status}
            value={tree?.status ? { id: tree.status, translationKey: `tree.statusTypes.${tree.status}` } : undefined}
          />
          <AccordionDropdownField
            items={Object.values(VitalityVigor).map(it => ({ id: it, translationKey: `tree.vitalityVigorTypes.${it}` }))}
            property={DisplayableTreeProperty.VitalityVigor}
            value={tree?.vitalityVigor ? { id: tree.vitalityVigor, translationKey: `tree.vitalityVigorTypes.${tree.vitalityVigor}` } : undefined}
          />
          <AccordionDropdownField
            items={booleanItems}
            property={DisplayableTreeProperty.FoliageNoneSeasonal}
            value={tree?.foliageNoneSeasonal !== null && tree?.foliageNoneSeasonal !== undefined ? { id: `${tree?.foliageNoneSeasonal}`, translationKey: `details.properties.booleanLabels.${tree?.foliageNoneSeasonal}` } : undefined}
          />
          <AccordionDropdownField
            items={booleanItems}
            property={DisplayableTreeProperty.FoliageNoneDead}
            value={tree?.foliageNoneDead !== null && tree?.foliageNoneDead !== undefined ? { id: `${tree?.foliageNoneDead}`, translationKey: `details.properties.booleanLabels.${tree?.foliageNoneDead}` } : undefined}
          />
          <AccordionNumericInputField property={DisplayableTreeProperty.NormalFoliage} value={tree?.normalFoliage} defaultUnit editable />
          <AccordionNumericInputField property={DisplayableTreeProperty.ChloroticFoliage} value={tree?.chloroticFoliage} defaultUnit editable />
          <AccordionNumericInputField property={DisplayableTreeProperty.NecroticFoliage} value={tree?.necroticFoliage} defaultUnit editable />
          <NumberOfLimbs
            tree={tree}
            editing={editingSection === DataCollectionSections.TreeHealthAndSpeciesProfile}
            handleChange={handleChange}
          />
          <AccordionDropdownField
            items={Object.values(Fork).map(option => ({ id: option, translationKey: 'details.properties.forkTypes.' + option }))}
            property={'fork' as DisplayableTreeProperty}
            value={tree?.fork ? { id: tree.fork, translationKey: `details.properties.forkTypes.${tree.fork}` } : undefined}
          />
        </AccordionItem>
        <AccordionItem
          title={t(`treeDetails.dataCollection.${DataCollectionSections.LoadFactors}`)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.LoadFactors}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.LoadFactors}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.LoadFactors}`))}
        >
          <AccordionNumericInputField property={DisplayableTreeProperty.CrownLightExposure} value={tree?.crownLightExposure}/>
          <AccordionNumericInputField
            property={'criticalWindSpeed' as DisplayableTreeProperty}
            value={tree?.criticalWindSpeed}
            customUnit={Tree.getWindSpeedUnit(organization)}
          />
        </AccordionItem>
        <AccordionItem
          title={getAccordionTitleWithCounterBadge(t(`treeDetails.dataCollection.${DataCollectionSections.CrownAndBranches}`), tree?.observations.filter(it => it.affectedTreePart === 'crown').length)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.CrownAndBranches}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.CrownAndBranches}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.CrownAndBranches}`))}
        >
          {tree?.observations.filter(it => it.affectedTreePart === 'crown').map(observation => (
            <ObservationItem
              key={observation.name}
              observation={observation}
            />
          ))}
        </AccordionItem>
        <AccordionItem
          title={getAccordionTitleWithCounterBadge(t(`treeDetails.dataCollection.${DataCollectionSections.Trunk}`), tree?.observations.filter(it => it.affectedTreePart === 'trunk').length)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.Trunk}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.Trunk}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.Trunk}`))}
        >
          <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSubsection} setEditingSection={setEditingSubsection}>
            {tree?.observations.filter(it => it.affectedTreePart === 'trunk').map(observation => (
              <ObservationItem
                key={observation.name}
                observation={observation}
              />
            ))}
          </AccordionGroup>
        </AccordionItem>
        <AccordionItem
          title={getAccordionTitleWithCounterBadge(t(`treeDetails.dataCollection.${DataCollectionSections.RootsAndRootCollar}`), tree?.observations.filter(it => it.affectedTreePart === 'root').length)}
          open={openAccordionItem === t(`treeDetails.dataCollection.${DataCollectionSections.RootsAndRootCollar}`)}
          onHeadingClick={() => handleToggle(t(`treeDetails.dataCollection.${DataCollectionSections.RootsAndRootCollar}`))}
          className={getAccordionItemClassName(t(`treeDetails.dataCollection.${DataCollectionSections.RootsAndRootCollar}`))}
        >
          <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSubsection} setEditingSection={setEditingSubsection}>
            {tree?.observations.filter(it => it.affectedTreePart === 'root').map(observation => (
              <ObservationItem
                key={observation.name}
                observation={observation}
              />
            ))}
          </AccordionGroup>
        </AccordionItem>
      </Accordion>
    </ul>
  );
}
