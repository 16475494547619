import { DisplayableViItem, Latitude, Longitude, SafetyFactor, TreeDto } from './Tree';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { ViStatus } from './DetailedTree';
import { plainToInstance } from 'class-transformer';

export default class PartialTree {
  static fromDto(dto: Partial<TreeDto>) {
    return plainToInstance(PartialTree, {
      ...dto,
      location: dto.location ? dto.location.coordinates : null,
      managedAreaName: ((dto.managedArea?.name?.length ?? 0) > 0 ? dto.managedArea?.name : dto.managedArea?.code) ?? null,
      wireClearanceIssueDetected: dto.wireClearanceIssueDetected?.toString() ?? null,
      roadClearanceIssueDetected: dto.roadClearanceIssueDetected?.toString() ?? null,
      buildingClearanceIssueDetected: dto.buildingClearanceIssueDetected?.toString() ?? null,
      visibilityClearanceIssueDetected: dto.visibilityClearanceIssueDetected?.toString() ?? null,
      trafficSignClearanceIssueDetected: dto.trafficSignClearanceIssueDetected?.toString() ?? null
    });
  }

  readonly id: string = '';
  readonly managedAreaId: string | null = null;
  readonly managedAreaName: string | null = null;
  readonly genus: string | null = null;
  readonly species: string | null = null;
  readonly scientificName: string | null = null;
  readonly height: number | null = null;
  readonly trunkHeight: number | null = null;
  readonly trunkWidth: number | null = null;
  readonly trunkEllipseRadiusA: number | null = null;
  readonly trunkEllipseRadiusB: number | null = null;
  readonly trunkCircumference: number | null = null;
  readonly trunkDiameter: number | null = null;
  readonly canopyHeight: number | null = null;
  readonly canopyWidth: number | null = null;
  readonly canopyEllipseRadiusA: number | null = null;
  readonly canopyEllipseRadiusB: number | null = null;
  readonly canopyCircumference: number | null = null;
  readonly leafArea: number | null = null;
  readonly leafBiomass: number | null = null;
  readonly leafAreaIndex: number | null = null;
  readonly carbonStorage: number | null = null;
  readonly grossCarbonSequestration: number | null = null;
  readonly no2: number | null = null;
  readonly so2: number | null = null;
  readonly pm25: number | null = null;
  readonly co: number | null = null;
  readonly o3: number | null = null;
  readonly ndvi: number | null = null;
  readonly treeHealth: number | null = null;
  readonly carbonStorageEcoValue: number | null = null;
  readonly grossCarbonSequestrationEcoValue: number | null = null;
  readonly no2EcoValue: number | null = null;
  readonly so2EcoValue: number | null = null;
  readonly pm25EcoValue: number | null = null;
  readonly coEcoValue: number | null = null;
  readonly o3EcoValue: number | null = null;
  readonly avoidedRunoffEcoValue: number | null = null;
  readonly potentialEvapotranspiration: number | null = null;
  readonly transpiration: number | null = null;
  readonly oxygenProduction: number | null = null;
  readonly safetyFactorAt80Kmh: number | null = null;
  readonly safetyFactorAtDefaultWindSpeed: number | null = null;
  readonly externalId: string | null = null;
  readonly safetyFactors: SafetyFactor[] = [];
  readonly thermalComfort: number | null = null;
  readonly treeValueCavat: number | null = null;
  readonly treeValueRado: number | null = null;
  readonly treeValueKoch: number | null = null;
  readonly leaningAngle: number | null = null;
  readonly evaporation: number | null = null;
  readonly waterIntercepted: number | null = null;
  readonly avoidedRunoff: number | null = null;
  readonly leaningVector: { coordinates: [number, number, number] } | null = null;
  readonly location: [Longitude, Latitude, number] | null = null;
  readonly localizedLocation: { coordinates: [Longitude, Latitude, number] } | null = null;
  readonly cohort: Cohort | null = null;
  readonly dieback: number | null = null;
  readonly deadBranchesRatio: number | null = null;
  readonly status: string | null = null;
  readonly vitalityVigor: string | null = null;
  readonly crownLightExposure: number | null = null;
  readonly leafAreaPerCrownVolume: number | null = null;
  readonly liveCrownRatio: number | null = null;
  readonly slenderness: number | null = null;
  readonly viStatus: ViStatus | null = null;
  readonly hasViObservation: boolean | null = null;
  readonly hasMitigation: boolean | null = null;
  readonly hasAssessmentRequest: boolean | null = null;
  readonly mitigations: DisplayableViItem[] = [];
  readonly outlierHeightPerCrownVolume: boolean | null = null;
  readonly outlierHeightPerLeafArea: boolean | null = null;
  readonly outlierLeafAreaPerCrownVolume: boolean | null = null;
  readonly outlierTrunkDiameterPerCrownVolume: boolean | null = null;
  readonly outlierTrunkDiameterPerHeight: boolean | null = null;
  readonly outlierTrunkDiameterPerLeafArea: boolean | null = null;
  readonly overallOutlierIndex: number | null = null;
  readonly wireClearanceIssueDetected: string | null = null;
  readonly roadClearanceIssueDetected: string | null = null;
  readonly buildingClearanceIssueDetected: string | null = null;
  readonly visibilityClearanceIssueDetected: string | null = null;
  readonly trafficSignClearanceIssueDetected: string | null = null;
  readonly tmsCategory: string | null = null;
  readonly criticalRootZone: number | null = null;
  readonly structuralCriticalRootZone: number | null = null;
  readonly crownVolume: number | null = null;
  readonly ageClass: string | null = null;
  readonly cultivarOrVariety: string | null = null;
  readonly customerTreeId: string | null = null;
  readonly streetAddress: string | null = null;

  getSafetyFactor(windSpeed: number) {
    return this.safetyFactors.find(it => it.windSpeed === windSpeed)?.safetyFactor || null;
  }

  getWorldCoordinates(): [number, number] {
    const mercatorCoordinateMin = 20037508.34;
    if (!this.location) return [0, 0];
    return [
      (Math.atan(Math.exp((this.location[1] * Math.PI) / mercatorCoordinateMin)) * 360) / Math.PI - 90,
      (this.location[0] * 180) / mercatorCoordinateMin
    ];
  }

  get displayableWorldCoordinates(): string {
    const [x, y] = this.getWorldCoordinates();
    return `${x.toFixed(5)}, ${y.toFixed(5)}`;
  }
}
