import axios, { AxiosInstance } from 'axios';
import { DisplayableTreeProperty, Tree, TreeDto } from './Tree';
import { TreeStatistics, TreeStatisticsDto } from './TreeStatistics';
import { TreeImage } from './TreeImage';
import { AVAILABLE_DATES } from '../routes/Tree/fakes';
import { ReportData } from '../routes/Explore/reports/ReportData';
import { AdvancedFilterConfiguration } from '../routes/Explore/table-view/advanced-filter/AdvancedFilter';
import { Organization } from '../organization/Organization';
import DetailedTree, { Observation } from './DetailedTree';
import { FilteredPartialTree } from './FilteredPartialTree';
import { Flippers } from '../switches/Flippers';
import { GraphDto } from '../routes/Explore/workspace/components/GraphModal';
import { parseScanIntervalToBlobPath, TseTreeScan } from './TseTreeScan';
import { QueryClient } from 'react-query';
import FilterConfig, { getProperties } from '../filter/FilterConfig';
import getRuntimeConfig from '../RuntimeConfig';

type LegacyPaginatedPartialTreeList = {
  pagination: {
    prev: number | null,
    current: number,
    next: number | null
  },
  notFilteredCount: number,
  trees: FilteredPartialTree[],
  pinnedTrees: FilteredPartialTree[],
  pinnedTreesCount: number,
  managedAreas: { id: string, code: string }[],
  species: string[],
  total: number,
  totalFilteredByArea: number,
  header: TreeListHeader[]
};

type PaginatedPartialTreeList = {
  pagination: {
    prev: number | null,
    current: number,
    next: number | null
  },
  notFilteredCount: number,
  trees: FilteredPartialTree[],
  pinnedTrees: FilteredPartialTree[],
  pinnedTreesCount: number,
  managedAreas: { id: string, code: string }[],
  scientificNames: string[],
  speciesList: string[],
  genusList: string[],
  total: number,
  totalFilteredByArea: number,
  header: TreeListHeader[]
};

export type TreeListHeader = {
  name: keyof Tree,
  aggregates?: {
    min: number,
    max: number,
    avg: number,
    sum: number,
    median: number
  }
};

type PaginatedResponse = {
  pagination: {
    prev: number | null,
    current: number,
    next: number | null
  },
  pinnedTrees: Array<Partial<TreeDto> & { filtered: boolean }>,
  pinnedTreesCount: number,
  trees: Array<Partial<TreeDto> & { filtered: boolean }>,
  notFilteredCount: number,
  total: number,
  totalFilteredByArea: number,
  managedAreas: { id: string, code: string }[],
  scientificNames: string[],
  speciesList: string[],
  genusList: string[],
  header: {
    name: keyof Tree,
    aggregates?: {
      min: number,
      max: number,
      avg: number,
      sum: number,
      median: number
    }
  }[]
};

type DetailedTreeDto = Omit<TreeDto, 'images'> & {
  images: string[],
  tseTreeScans: TseTreeScan[],
  observations: Observation[]
};

export default class TreeService {
  private static readonly MAX_IMAGE_COUNT = AVAILABLE_DATES.length;
  private static readonly TREE_IMAGE_CACHE = new Map<string, TreeImage[]>();

  constructor(
    private readonly http: AxiosInstance,
    private readonly queryClient: QueryClient
  ) {
  }

  async getStatistics(organizationId: string, treeId: string) {
    const result = await this.http.get<TreeStatisticsDto[]>(
      `/v1/organizations/${organizationId}/trees/${treeId}/statistics`
    );
    return result.data.map(TreeStatistics.fromDto);
  }

  async fetchTableViewData(
    organization: Organization,
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: (keyof Tree)[],
    filterIds: string[],
    page: number,
    sort: string | null,
    signal?: AbortSignal,
    treeId?: string | null,
    advancedFilterConfiguration?: AdvancedFilterConfiguration | null,
    windSpeed?: number
  ): Promise<LegacyPaginatedPartialTreeList> {
    const fieldsToFetch = Array.from(new Set(fields));

    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    fieldsToFetch.forEach(it => params.append('field', it));
    filterIds.forEach(it => params.append('filterId', it));
    params.append('page', `${page}`);
    if (sort) params.append('sort', `${sort}`);
    if (treeId) params.append('treeId', treeId);
    if (advancedFilterConfiguration) {
      const transformedAdvancedFilter = { ...advancedFilterConfiguration };
      params.append('advancedFiltering', JSON.stringify(transformedAdvancedFilter));
    }
    if (windSpeed) params.append('windSpeed', JSON.stringify(windSpeed));
    if (organization.isEnabled(Flippers.dashboardRedesign)) {
      params.append('dashboardRedesignFlipper', JSON.stringify(true));
    }

    type PaginatedResponse = {
      pagination: {
        prev: number | null,
        current: number,
        next: number | null
      },
      pinnedTrees: Array<Partial<TreeDto> & { filtered: boolean }>,
      pinnedTreesCount: number,
      trees: Array<Partial<TreeDto> & { filtered: boolean }>,
      notFilteredCount: number,
      total: number,
      totalFilteredByArea: number,
      managedAreas: { id: string, code: string }[],
      species: string[],
      header: {
        name: keyof Tree,
        aggregates?: {
          min: number,
          max: number,
          avg: number,
          sum: number,
          median: number
        }
      }[]
    };

    const { data: response } = await this.http.get<PaginatedResponse>(
      `/v1/organizations/${organizationId}/table-views`,
      { params, signal }
    );

    return {
      ...response,
      notFilteredCount: response.notFilteredCount,
      pinnedTrees: response.pinnedTrees.map(FilteredPartialTree.fromDto),
      pinnedTreesCount: response.pinnedTreesCount,
      trees: response.trees.map(FilteredPartialTree.fromDto)
    };
  }

  async fetchWorkspaceData(
    organization: Organization,
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: (keyof Tree)[],
    filterConfig: FilterConfig,
    page: number,
    sort: string | null,
    signal?: AbortSignal,
    treeId?: string | null,
    windSpeed?: number,
    taskTemplateId?: string
  ): Promise<PaginatedPartialTreeList> {
    const fieldsToFetch = Array.from(new Set([...fields, ...getProperties(filterConfig)]));

    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    fieldsToFetch.forEach(it => params.append('field', it));
    params.append('filterConfig', JSON.stringify(filterConfig));
    params.append('page', `${page}`);
    if (sort) params.append('sort', `${sort}`);
    if (treeId) params.append('treeId', treeId);
    if (windSpeed) params.append('windSpeed', JSON.stringify(windSpeed));
    if (organization.isEnabled(Flippers.dashboardRedesign)) {
      params.append('dashboardRedesignFlipper', JSON.stringify(true));
    }
    if (taskTemplateId) params.append('taskTemplateId', taskTemplateId);

    const { data: response } = organization.isEnabled(Flippers.release24q3) ?
      await this.http.get<PaginatedResponse>(`/v1/organizations/${organizationId}/workspace`, { params, signal }) :
      await this.http.get<PaginatedResponse>(`/v1/organizations/${organizationId}/legacy-workspace`, { params, signal });

    return {
      ...response,
      notFilteredCount: response.notFilteredCount,
      pinnedTrees: response.pinnedTrees.map(FilteredPartialTree.fromDto),
      pinnedTreesCount: response.pinnedTreesCount,
      trees: response.trees.map(FilteredPartialTree.fromDto)
    };
  }

  async fetchGraphData(
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: (keyof Tree)[],
    filterConfig: FilterConfig,
    taskOrTaskTemplateId: string | undefined,
    property: keyof Tree,
    isEnum: boolean
  ): Promise<GraphDto> {
    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    if (taskOrTaskTemplateId) params.append('taskOrTaskTemplateId', taskOrTaskTemplateId);
    fields.forEach(it => params.append('field', it));
    params.append('filterConfig', JSON.stringify(filterConfig));
    params.append('property', property);
    params.append('isEnum', isEnum.toString());

    const { data: response } = await this.http.get<GraphDto>(
      `/v1/organizations/${organizationId}/workspace/graph`,
      { params }
    );

    return response;
  }

  async fetchReportData(
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: (keyof Tree)[],
    filterIds: string[]
  ): Promise<ReportData> {
    const params = new URLSearchParams();

    const fieldsToFetch = Array.from(new Set(fields));

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    fieldsToFetch.forEach(it => params.append('field', it));
    filterIds.forEach(it => params.append('filterId', it));

    const { data: response } = await this.http.get<ReportData>(
      `/v1/organizations/${organizationId}/reports`,
      { params }
    );

    return response;
  }

  async fetchWorkspaceReportData(
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: (keyof Tree)[],
    filterIds: string[],
    filterConfig: FilterConfig,
    taskTemplateId: string | undefined
  ): Promise<ReportData> {
    const params = new URLSearchParams();

    const fieldsToFetch = Array.from(new Set(fields));

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    if (taskTemplateId) params.append('taskTemplateId', taskTemplateId);
    fieldsToFetch.forEach(it => params.append('field', it));
    filterIds.forEach(it => params.append('filterId', it));
    params.append('filterConfig', JSON.stringify(filterConfig || []));

    const { data: response } = await this.http.get<ReportData>(
      `/v1/organizations/${organizationId}/workspace/reports`,
      { params }
    );

    return response;
  }

  async pinTree(organizationId: string, treeId: string): Promise<boolean> {
    const url = `/v1/organizations/${organizationId}/trees/${treeId}/pins`;
    const response = await this.http.post(url);
    return response.status === 201;
  }

  async deletePin(organizationId: string, treeId: string): Promise<boolean> {
    const url = `/v1/organizations/${organizationId}/trees/${treeId}/pins`;
    const response = await this.http.delete(url);
    return response.status === 200;
  }

  async deleteTree(organizationId: string, treeId: string): Promise<boolean> {
    const url = `/v1/organizations/${organizationId}/trees/${treeId}`;
    try {
      const response = await this.http.delete(url);
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }

  async find(organization: Organization, id: string): Promise<DetailedTree> {
    const response = await this.http.get<DetailedTreeDto>(`/v1/organizations/${organization.id}/trees/${id}`);

    const images = await this.getImagesOfTree(organization, id, response.data);

    return DetailedTree.fromDetailedTreeDto({ ...response.data, images, isMetric: organization.isMetric });
  }

  async findByExternalId(organizationId: string, externalId: string, limit?: number): Promise<TreeDto[]> {
    const response = await this.http.get<TreeDto[]>(
      `/v1/organizations/${organizationId}/trees/externalId/${externalId}${limit ? `?limit=${limit}` : ''}`
    );
    return response.data;
  }

  async findByCustomerTreeId(organizationId: string, customerTreeId: string, limit?: number): Promise<TreeDto[]> {
    const response = await this.http.get<TreeDto[]>(
      `/v1/organizations/${organizationId}/trees/customerTreeId/${customerTreeId}${limit ? `?limit=${limit}` : ''}`
    );
    return response.data;
  }

  private async getSingleImages(
    organization: Organization,
    managedAreaCode: string,
    treeId: string,
    tseTreeScans: TseTreeScan[]
  ): Promise<any[]> {
    const scanInterval = parseScanIntervalToBlobPath(tseTreeScans.at(-1)?.scanInterval ? `${tseTreeScans.at(-1)!.scanInterval}` : '');
    try {
      const croppedImageJsonUrl = organization.getCDNUrlOfTreeDataFromRelativePath(`/${organization.tseBlobContainer}/${managedAreaCode}/${scanInterval}/cropped_images/${treeId}/images.json`);
      const croppedImageJson = await axios.get<{ cropped_fname: string, db_info: { blob_path: string } }[]>(croppedImageJsonUrl, {
        withCredentials: true
      })
        .then((response: any) => response.data);
      return croppedImageJson.map(image => organization.getCDNUrlOfTreeDataFromRelativePath(`/${organization.tseBlobContainer}/${managedAreaCode}/${scanInterval}/cropped_images/${treeId}/${image.cropped_fname}`)).slice(0, 5);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Unable to fetch cropped images, trying single images');
    }

    try {
      const singleImageJsonUrl = organization.getCDNUrlOfTreeDataFromRelativePath(`/${organization.tseBlobContainer}/${managedAreaCode}/${scanInterval}/single_images/${treeId}/single_images.json`);
      const singleImageJson = await axios.get(singleImageJsonUrl, {
        withCredentials: true
      })
        .then((response: any) => response.data);
      if (!singleImageJson.images?.length) {
        return [];
      }
      return singleImageJson.images.map(singleImageData => (organization.getCDNUrlOfTreeDataFromRelativePath(`/${organization.tseBlobContainer}/${managedAreaCode}/${scanInterval}/single_images/${treeId}/${singleImageData?.path}`)));
    } catch (error) {
      return [];
    }
  }

  private async fetchTreeImagesUsingLocalizedLocation(
    organization: Organization,
    treeId: string,
    location: TreeDto['localizedLocation'],
    tseTreeScans: TseTreeScan[]
  ): Promise<TreeImage[]> {
    if (TreeService.TREE_IMAGE_CACHE.has(treeId)) {
      return TreeService.TREE_IMAGE_CACHE.get(treeId)!;
    }

    const [x, y, z] = location.coordinates;
    const scanInterval = tseTreeScans.at(-1)?.scanInterval ? `&scanInterval=${tseTreeScans.at(-1)!.scanInterval}` : '';
    try {
      const { data: imageUrls } = await this.http.get<{ gufPath?: string, path: string }[]>(
        `/v0/organizations/${organization.id}/images?x=${x}&y=${y}&z=${z}${scanInterval}`
      );
      const images = await Promise.all(
        imageUrls
          .map(it => it.gufPath ?? it.path)
          .filter(Boolean)
          .slice(0, TreeService.MAX_IMAGE_COUNT)
          .map(url => new TreeImage(organization.getCDNUrlOfExportedDataFromRelativePath(url)))
      );
      TreeService.TREE_IMAGE_CACHE.set(treeId, images);
      return images;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    }
  }

  async listVisibleFrom(organizationId: string, origin: number[], epsilon = 50): Promise<Tree[]> {
    const [x, y] = origin;
    const { data } = await this.http.get<TreeDto[]>(
      `/v1/organizations/${organizationId}/trees?x=${x}&y=${y}&epsilon=${epsilon}`
    );

    return data.map(Tree.fromDto);
  }

  async loadManagedAreas(organizationId: string, withIncomplete = false) {
    await this.http.post(`/v1/organizations/${organizationId}/managed-area-loading-jobs`, null, {
      params: { withIncomplete }
    });
  }

  async getShpExport(organizationId: string, reverseMASelection: boolean, managedAreaId: string[], filterIds: string[], fields: DisplayableTreeProperty[], filterConfig?: FilterConfig) {
    return await this.http.get(`/v1/organizations/${organizationId}/shp-export`,
      {
        params: { reverseMASelection, managedAreaId, filterIds, fields, filterConfig: filterConfig ? JSON.stringify(filterConfig) : undefined },
        responseEncoding: 'binary',
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/octet-stream' }
      }
    );
  }

  async update(organizationId: string, treeId: string, payload: Partial<TreeDto>) {
    await this.http.patch(`v1/organizations/${organizationId}/trees/${treeId}`, payload);
    await this.queryClient.invalidateQueries(`tree-${organizationId}-${treeId}`);
  }

  async uploadObservationPhoto(organizationId: string, treeId: string, observationName: string, imageUrl: string): Promise<string> {
    const formData = new FormData();
    const blobImage = base64ToBlob(imageUrl, 'image/png');
    formData.append('image', blobImage);

    const { data } = await this.http.post(`/v1/organizations/${organizationId}/trees/${treeId}/observations/${observationName}`, formData);
    return data;
  }

  async getTreeCountByManagedArea(
    organizationId: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    filterForViStatus?: boolean
  ): Promise<number> {
    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    if (filterForViStatus) params.append('filterForViStatus', 'true');
    const { data } = await this.http.get(`v1/organizations/${organizationId}/tree-count-by-managed-area`, { params });
    return data;
  }

  async getSpeciesListsByManagedAreas(organizationId: string, managedAreaIds: string[], reverseMASelection: boolean) {
    const params = new URLSearchParams();
    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    const { data } = await this.http.get(`v1/organizations/${organizationId}/species-list`, { params });
    return data;
  }

  private async getImagesOfTree(organization: Organization, treeId: string, data: DetailedTreeDto) {
    const singleImages = await this.getSingleImages(organization, data?.managedArea?.code, data?.externalId, data.tseTreeScans);
    if (singleImages.length) {
      return singleImages.map(si => new TreeImage(si));
    }

    try {
      return (data.images || []).length > 0
        ? data.images.slice(0, TreeService.MAX_IMAGE_COUNT).map(url => new TreeImage(organization.getCDNUrlOfExportedDataFromRelativePath(url)))
        : await this.fetchTreeImagesUsingLocalizedLocation(organization, treeId, data.localizedLocation, data.tseTreeScans);
    } catch (e){
      console.error(e);
      return [];
    }
  }
}

function base64ToBlob(base64Data: string, contentType: string): Blob {
  const byteCharacters = window.atob(base64Data.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}
