export enum Flippers {
  davey = 'davey',
  dashboardRedesign = 'dashboardRedesign',
  compareToCohort = 'compareToCohort',
  newPlatformProperties = 'newPlatformProperties',
  workspace = 'workspace',
  release24q3 = 'release24q3',
  windspeedSlider = 'windspeedSlider',
  carbonRedesign = 'carbonRedesign',
  wireClearance = 'wireClearance',
  snowflakeChart = 'snowflakeChart',
  taskManagerRework = 'taskManagerRework',
  sprint77 = 'sprint77',
  directBlobAccess = 'directBlobAccess'
}
