import SpeciesInItalic from '../Explore/workspace/components/SpeciesInItalic';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../tree/DetailedTree';
import React, {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Task } from '../../task-manager/Task';
import { Condition as TreeCondition, CrownTransparency, DisplayableTreeProperty, Fork, Tree } from '../../tree/Tree';
import NoData from './NoData';
import { Dropdown, Modal, OverflowMenu, TextInput } from '@carbon/react';
import { useCurrentAccount } from '../../account/useAccounts';
import { Flippers } from '../../switches/Flippers';
import { ChevronDown, ChevronUp, Close, Edit, TrashCan, WarningAltFilled } from '@carbon/icons-react';
import TreeDeleteModal from './TreeDeleteModal';
import AbsoluteModal from '../../components/Modal/absolute-modal/AbsoluteModal';
import { Button as CarbonButton } from '@carbon/react/lib/components/Button';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { TreeStatus } from '../../property-enums/TreeStatus';
import { VitalityVigor } from '../../property-enums/VitalityVigor';
import { OverheadUtilities } from '../../property-enums/OverheadUtilities';

enum CarbonLoadingState {
  Active = 'active',
  Inactive = 'inactive',
  Finished = 'finished',
  Error = 'error'
}

const propertyOptions = {
  [DisplayableTreeProperty.Condition]: Object.values(TreeCondition),
  [DisplayableTreeProperty.CrownTransparency]: Object.values(CrownTransparency),
  [DisplayableTreeProperty.Fork]: Object.values(Fork),
  [DisplayableTreeProperty.IncludedBark]: [true, false],
  [DisplayableTreeProperty.FoliageNoneSeasonal]: [true, false],
  [DisplayableTreeProperty.FoliageNoneDead]: [true, false],
  [DisplayableTreeProperty.Status]: Object.values(TreeStatus),
  [DisplayableTreeProperty.VitalityVigor]: Object.values(VitalityVigor),
  [DisplayableTreeProperty.CrownTransparency]: Object.values(CrownTransparency),
  [DisplayableTreeProperty.CrownLightExposure]: new Array(5).fill(0).map((_, i) => i),
  [DisplayableTreeProperty.OverheadUtilities]: Object.values(OverheadUtilities),
  [DisplayableTreeProperty.AgeClass]: ['young', 'mature_veteran']
};

export default function Header({ tree }: {
  tree: DetailedTree | null,
  task: Task | null,
  isTreeEdited: boolean
}) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { treeService, genusService } = useContext(DependencyInjectionContext);

  const [deletingTree, setDeletingTree] = useState(false);
  const [editingTree, setEditingTree] = useState(false);
  const [loadingState, setLoadingState] = useState<CarbonLoadingState>(CarbonLoadingState.Inactive);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [genusSearchKey, setGenusSearchKey] = useState('');
  const [speciesSearchKey, setSpeciesSearchKey] = useState('');
  const [genusOptions, setGenusOptions] = useState<string[]>([]);
  const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);

  const [updatedTree, setUpdatedTree] = useState<Partial<DetailedTree> | null>(tree);

  useEffect(() => {
    setUpdatedTree(tree);
  }, [tree]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (genusSearchKey.length === 0) return;
      genusService.listGeneraFromTse(account.organization.id, genusSearchKey)
        .then(setGenusOptions);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [genusSearchKey]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (speciesSearchKey.length === 0) return;
      genusService.listSpeciesFromTse(account.organization.id, speciesSearchKey)
        .then(setSpeciesOptions);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [speciesSearchKey]);

  const renderedGenus = tree?.genus || <NoData />;
  const renderedSpecies = tree?.species ? <SpeciesInItalic species={tree.species} /> : <NoData />;

  const handleSave = async () => {
    if (!updatedTree?.id || !tree) return;

    setLoadingState(CarbonLoadingState.Active);
    const updatedTreeProperties = Object.fromEntries(
      Object.entries(updatedTree).filter(([key, value]) => value !== tree[key])
    );

    try {
      await treeService.update(account.organization.id, updatedTree.id, updatedTreeProperties);
      setLoadingState(CarbonLoadingState.Finished);
      setEditingTree(false);
    } catch (e) {
      setLoadingState(CarbonLoadingState.Error);
    } finally {
      setTimeout(() => {
        setLoadingState(CarbonLoadingState.Inactive);
      }, 500);
    }
  };

  const openEditingModal = () => {
    setEditingTree(true);
    setOptionsMenuOpen(false);
  };

  const handleCloseModal = () => {
    setUpdatedTree(tree);
    setEditingTree(false);
  };

  const getItems = (property: DisplayableTreeProperty) => {
    return propertyOptions[property].map(it => ({
      label: Tree.renderPropertyValue(property, it, t),
      value: it
    })) || [];
  };

  const TextInputField = ({ property, type }: { property: DisplayableTreeProperty, type?: string }) => {
    const [inputValue, setInputValue] = useState(updatedTree?.[property] || '');

    return (
      <TextInput
        id={`${property}-input`}
        type={type || 'text'}
        labelText={Tree.renderPropertyName(property, t, account.organization)}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onBlur={e => setUpdatedTree(prev => ({ ...prev, [property]: e.target.value }))}
      />
    );
  };

  const DropdownField = ({ property }: { property: DisplayableTreeProperty }) => {
    const items = getItems(property);
    const [selectedItem, setSelectedItem] = useState(items.find(it => it.value === updatedTree?.[property]));

    const selectItem = (item: { label: string, value: string}) => {
      setSelectedItem(item);
      setUpdatedTree(prev => ({ ...prev, [property]: item.value }));
    };

    return (
      <Dropdown
        titleText={Tree.renderPropertyName(property, t, account.organization)}
        onChange={it => selectItem(it.selectedItem)}
        selectedItem={selectedItem}
        items={items}
        itemToString={item => item?.label || ''}
        id={`${property}-dropdown`}
        label=''
      />
    );
  };

  return (
    <div className="flex justify-between w-full twp">
      <div className="flex gap-2 items-center">
        <div className="grid grid-cols-7 gap-5">
          <div>
            <Label label={t('treeDetails.inventory.genus')} />
            <p className="text-sm font-semibold"><i>{renderedGenus}</i></p>
          </div>
          <div>
            <Label label={t('treeDetails.inventory.species')} />
            <p className="text-sm font-semibold">{renderedSpecies}</p>
          </div>
          <div>
            <Label label={t('details.properties.commonName')} />
            <p className="text-sm font-semibold">{tree?.commonName || <NoData />}</p>
          </div>
          <div>
            <Label label={t('details.properties.address')} />
            <p className="text-sm font-semibold">{tree?.streetAddress || <NoData />}</p>
          </div>
          <div>
            <Label label={t('tree.tmsCat')} />
            <p className="text-sm font-semibold capitalize">{Tree.renderPropertyValue('tmsCategory', tree?.tmsCategory, t) || <NoData />}</p>
          </div>
          <div>
            <Label label={t('treeDetails.lastScanDate')} />
            <p className="text-sm font-semibold">{tree?.recordingDate ? formatDate(tree.recordingDate) : <NoData />}</p>
          </div>
          <div>
            <Label label={t('treeDetails.lastUpdate')} />
            <p className="text-sm font-semibold">{tree?.lastUpdatedAt ? formatDate(tree.lastUpdatedAt) : <NoData />}</p>
          </div>
        </div>
      </div>
      {account.organization.isEnabled(Flippers.sprint77) && (
        <>
          <OverflowMenu
            direction="bottom"
            flipped
            size="lg"
            open={optionsMenuOpen}
            onOpen={() => setOptionsMenuOpen(true)}
            onClose={() => setOptionsMenuOpen(false)}
          >
            {account.isAdminOrAbove() && (
              <>
                <CarbonButton
                  kind="ghost"
                  className="flex items-center gap-4 p-0 px-4 text-[var(--cds-text-primary)] w-full justify-start text-nowrap"
                  onClick={() => setDeletingTree(true)}
                >
                  <TrashCan/>
                  {t('treeDetails.deleteTree.button')}
                </CarbonButton>
                <CarbonButton
                  kind="ghost"
                  className="flex items-center gap-4 p-0 px-4 text-[var(--cds-text-primary)] w-full justify-start text-nowrap"
                  onClick={openEditingModal}
                >
                  <Edit/>
                  {t('treeDetails.editTree.button')}
                </CarbonButton>
              </>
            )}
          </OverflowMenu>
          <AbsoluteModal isVisible={deletingTree} onHide={() => setDeletingTree(false)}>
            <TreeDeleteModal setDeletingTree={setDeletingTree} treeId={tree?.id}/>
          </AbsoluteModal>
        </>
      )}
      <Modal
        open={editingTree}
        onRequestClose={handleCloseModal}
        onRequestSubmit={handleSave}
        modalHeading={t('treeDetails.editTree.title')}
        primaryButtonText={t('treeDetails.editTree.save')}
        secondaryButtonText={t('treeDetails.editTree.cancel')}
        loadingStatus={loadingState}
        size={'sm'}
      >
        <div className="flex gap-2">
          <WarningAltFilled size={24} style={{ color: 'FFB700' }}/>
          <p>{t('treeDetails.editTree.warning')}</p>
        </div>
        <EditingSection label={t('treeDetails.inventory.additionalInformation')}>
          <TextInputField property={DisplayableTreeProperty.CultivarOrVariety}/>
          <TextInputField property={DisplayableTreeProperty.CustomerSiteId}/>
          <TextInputField property={DisplayableTreeProperty.CustomerTagId}/>
          <TextInputField property={DisplayableTreeProperty.CustomerTreeId}/>
          <TextInputField property={DisplayableTreeProperty.Owner}/>
          <TextInputField property={DisplayableTreeProperty.PlantingYear} type='number' />
        </EditingSection>
        <EditingSection label={t('treeDetails.inventory.assignmentAndTreeId')}>
          <TextInputField property={DisplayableTreeProperty.AddressFromParcel}/>
          <DropdownField property={DisplayableTreeProperty.AgeClass}/>
          <TextInputField property={DisplayableTreeProperty.CommonName}/>
          <TextInputField property={DisplayableTreeProperty.ParkName}/>
          <SearchableDropdown
            property={DisplayableTreeProperty.Genus}
            options={genusOptions}
            searchTerm={genusSearchKey}
            setSearchTerm={setGenusSearchKey}
            value={updatedTree?.genus || ''}
            onSelect={it => setUpdatedTree(prev => ({ ...prev, genus: it }))}
          />
          <SearchableDropdown
            property={DisplayableTreeProperty.Species}
            options={speciesOptions}
            searchTerm={speciesSearchKey}
            setSearchTerm={setSpeciesSearchKey}
            value={updatedTree?.species || ''}
            onSelect={it => setUpdatedTree(prev => ({ ...prev, species: it }))}
          />
          <TextInputField property={DisplayableTreeProperty.StreetAddress}/>
        </EditingSection>
        <EditingSection label={t('treeDetails.inventory.dimensions')}>
          <TextInputField property={DisplayableTreeProperty.CanopyHeight} type='number'/>
          <TextInputField property={DisplayableTreeProperty.CanopyWidth} type='number'/>
          <TextInputField property={DisplayableTreeProperty.TrunkCircumference} type='number'/>
          <TextInputField property={DisplayableTreeProperty.TrunkDiameter} type='number'/>
          <TextInputField property={DisplayableTreeProperty.TrunkHeight} type='number'/>
          <TextInputField property={DisplayableTreeProperty.LeaningAngle} type='number'/>
          <TextInputField property={DisplayableTreeProperty.NumberOfStems} type='number'/>
          <TextInputField property={DisplayableTreeProperty.Height} type='number'/>
        </EditingSection>
        <EditingSection label={t('treeDetails.inventory.healthAndVitality')}>
          <DropdownField property={DisplayableTreeProperty.Condition}/>
          <DropdownField property={DisplayableTreeProperty.CrownTransparency}/>
          <TextInputField property={DisplayableTreeProperty.Dieback} type='number'/>
          <TextInputField property={DisplayableTreeProperty.LeafArea} type='number'/>
          <TextInputField property={DisplayableTreeProperty.LeafAreaIndex} type='number'/>
        </EditingSection>
        <EditingSection label={t('treePropertySelector.structuralAssessment')}>
          <DropdownField property={DisplayableTreeProperty.Fork}/>
          <DropdownField property={DisplayableTreeProperty.IncludedBark}/>
          {/*TODO: <TextInputField property={DisplayableTreeProperty.NumberOfLimbs} type='number'/>*/}
        </EditingSection>
        <EditingSection label={t('treePropertySelector.treeHealthAndSpeciesProfile')}>
          <DropdownField property={DisplayableTreeProperty.FoliageNoneDead}/>
          <DropdownField property={DisplayableTreeProperty.FoliageNoneSeasonal}/>
          <DropdownField property={DisplayableTreeProperty.Status}/>
          <DropdownField property={DisplayableTreeProperty.VitalityVigor}/>
        </EditingSection>
        <EditingSection label={t('treePropertySelector.loadFactors')}>
          <DropdownField property={DisplayableTreeProperty.CrownLightExposure}/>
        </EditingSection>
        <EditingSection label={t('treePropertySelector.siteFactors')}>
          <DropdownField property={DisplayableTreeProperty.OverheadUtilities}/>
        </EditingSection>
        <div className='pb-28'></div>
      </Modal>
    </div>
  );
}

const SearchableDropdown = (props: { options: string[], searchTerm: string, setSearchTerm: Dispatch<SetStateAction<string>>, onSelect: (it) => void, value: string, property: DisplayableTreeProperty }) => {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    props.setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative twp text-sm font-normal my-4" ref={dropdownRef}>
      <div>
        <h3 className="text-xs text-[var(--cds-text-secondary)]">
          {Tree.renderPropertyName(props.property, t, account.organization)}
        </h3>
        <div className="flex items-center relative my-2" onClick={() => setIsOpen(true)}>
          <input
            type="text"
            placeholder={props.value}
            value={props.searchTerm}
            onChange={handleSearch}
            className="bg-[var(--cds-field)] p-2 pl-4 pr-8 border-2 border-[var(--cds-field)]
            focus:outline-none focus:border-[var(--cds-interactive)] w-full placeholder:text-[var(--cds-text-primary)]"
          />
          <div className="absolute right-4 flex">
            {
              props.searchTerm &&
                <div className="pr-2 border-r-[1px] border-[var(--cds-border-strong)]">
                  <Close className="cursor-pointer" onClick={() => props.setSearchTerm('')} />
                </div>
            }
            {
              isOpen
                ? <ChevronUp
                  className="ml-2 cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                />
                :
                <ChevronDown className="ml-2 cursor-pointer" />
            }
          </div>
          <div className="absolute bottom-0 bg-orchid-500 w-full h-0 border-b-[1px] border-[var(--cds-border-strong)]" />
        </div>
      </div>

      {isOpen && (
        <div
          className="absolute top-full left-0 z-50 max-h-64 w-full overflow-y-auto bg-[var(--cds-field)]"
          style={{ boxShadow: '0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3))' }}
        >
          <ul>
            {props.options.map(option => (
              <li
                key={option}
                className={`${option === props.value ? 'bg-[var(--cds-layer-selected)]' : ''} px-4 py-3 cursor-pointer`}
                onClick={e => {
                  e.preventDefault();
                  props.onSelect(option);
                  props.setSearchTerm('');
                  setIsOpen(false);
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const EditingSection = ({ label, children }: { label: string, children: ReactNode }) => {
  return (
    <>
      <div className="flex flex-col gap-4 my-4">
        <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full"></div>
        <h3 className="text-xs font-medium">
          {label}
        </h3>
      </div>
      <div className="space-y-4">
        {children}
      </div>
    </>
  );
};

const Label = ({ label }: { label: string }) => {
  return (
    <span className="text-sm text-[var(--cds-text-secondary)]">{label}</span>
  );
};

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate();
  const monthYear = date.toLocaleDateString('en-GB', {
    month: 'long',
    year: 'numeric'
  });

  const daySuffix = (n: number) => {
    if (n >= 11 && n <= 13) return 'th';
    switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
    }
  };

  const formattedMonthYear = monthYear.replace(' ', ', ');

  return `${day}${daySuffix(day)} ${formattedMonthYear}`;
}
