import React, { ChangeEvent, useContext, useRef } from 'react';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import styles from './TableRow.module.scss';
import { useTranslation } from 'react-i18next';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { FilteredPartialTree } from '../../../../tree/FilteredPartialTree';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import CohortConfig from '../../../../components/cohort/CohortConfig';
import { IssuesFoundIcon, NoIssuesIcon } from '../../../../components/UI/Icon/Icon';
import SpeciesInItalic from './SpeciesInItalic';
import ScientificNameInItalic from './ScientificNameInItalic';
import { useNavigate } from 'react-router-dom';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { OpenNewWindow } from 'iconoir-react';
import { Flippers } from '../../../../switches/Flippers';
import { Property } from '../TreeTable';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';

interface TableRowProps {
  tree: FilteredPartialTree,
  index: number,
  properties: Property[],
  selectedTreeId: string | null,
  onSelect: () => unknown,
  windSpeed: number,
  selectedTreeProperty: DisplayableTreeProperty | null,
  propertyConfigs: PropertyConfiguration[],
  showCheckboxes?: boolean,
  isSelected?: boolean,
  handleTreeSelection?: (event: ChangeEvent<HTMLInputElement>, treeId: string) => unknown
}

export default function TableRow(props: TableRowProps) {
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { organization } = useCurrentAccount();

  const isSelected = props.tree.id === props.selectedTreeId;
  const navigate = useNavigate();

  const className = [
    styles.row,
    isSelected ? styles.active : '',
    props.tree.filtered ? styles.filtered : ''
  ].join(' ');

  const rowRef = useRef<HTMLDivElement | null>(null);

  const selectRow = () => {
    if (!props.tree.filtered) {
      props.onSelect();
    }
  };

  const navigateToTreeDetails = (event, treeId: string) => {
    event.stopPropagation();
    navigate(`/organizations/${organization.id}/inventory/trees/${treeId}`);
  };

  return (
    <div
      className={className}
      style={{ gridTemplateColumns: `${props.showCheckboxes ? '4.5rem' : ''} repeat(${props.properties.length}, 200px) 1fr` }}
      ref={rowRef}
      onClick={() => selectRow()}
    >
      {props.showCheckboxes && <div className={`${styles.column} bg-outer-space-700 flex justify-center items-center whitespace-nowrap text-sm text-gray-900 border-t border-r border-outer-space-800`}>
        <Checkbox
          checked={!!props.isSelected}
          onChange={event => props.handleTreeSelection!(event, props.tree.id)}
        />
      </div>}
      {props.properties.map(property => {
        const value =
          property === DisplayableTreeProperty.SafetyFactors
            ? props.tree.getSafetyFactor(props.windSpeed)
            : (property === 'managedAreaId' ? props.tree['managedAreaName'] : props.tree[property]);

        const getPropertyConfigColor = () => {
          const propertyConfig = props.propertyConfigs.find(it => it.property === property);
          if (propertyConfig) {
            const index = propertyConfig.getRangeIndexForValue(value);
            const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
            return colors[index];
          }

          return '255, 255, 255';
        };

        const getCohortColor = () => {
          if (!props.tree.cohort?.cohortValues[property]) return '255, 255, 255';
          return new CohortConfig(props.tree.cohort?.cohortValues[property]).getColorOfValue(value);
        };

        const color = urlContext.getColoringType() === 'cohort' ? getCohortColor() : getPropertyConfigColor();

        return (
          <div
            key={`tree-${props.index}-${property}`}
            className={`twp ${styles.column} bg-outer-space-700 border-t border-r border-outer-space-800 text-left ${property === 'externalId' ? 'sticky left-[4.5rem]' : ''}`}
            style={property === props.selectedTreeProperty ? { background: `rgba(${color}, 0.1)` } : {}}
          >
            {property === 'externalId' ?
              <div
                className="flex items-center gap-2"
                onClick={e => navigateToTreeDetails(e, props.tree.id)}
              >
                <span>{value}</span>
                <OpenNewWindow />
              </div>
              :
              <TableCell
                property={property}
                value={value}
                isSelected={property === props.selectedTreeProperty}
                color={color}
                tree={props.tree}
              />}
          </div>
        );
      })}
      {
        !organization.isEnabled(Flippers.release24q3) && (
          <div
            key={`tree-${props.index}-placeholder`}
            className={`twp ${styles.column} bg-outer-space-700 border-t border-outer-space-800 ${styles.columnPlaceholder}`}
          />
        )
      }
    </div>
  );
}

function TableCell({ property, value, isSelected, color, tree }: TableCellProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const locale = navigator.language;

  if (property === DisplayableTreeProperty.Status) {
    return <div>{value ? t('tree.statusTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.VitalityVigor) {
    return <div>{value ? t('tree.vitalityVigorTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.ViStatus) {
    return <div>{value ? t('virtualInspection.status.' + value) : '-'}</div>;
  }

  if (Tree.isViBooleanProperty(property)) {
    return <div className={styles.inspectionCell}>{value ? <NoIssuesIcon /> : <IssuesFoundIcon />}</div>;
  }

  if (Tree.isBooleanProperty(property)) {
    if (value === true) return <div>{t('details.properties.booleanLabels.true')}</div>;
    if (value === false) return <div>{t('details.properties.booleanLabels.false')}</div>;
  }

  if (value instanceof Array) {
    return <span
      className={`${styles.columnValue} bg-outer-space-700`}
      style={isSelected ? { color: `rgb(${color})` } : {}}
      title={value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    >
      {value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    </span>;
  }

  const getComponent = (value: string) => {
    switch (property) {
    case (DisplayableTreeProperty.Genus): return (<i>{value}</i>);
    case (DisplayableTreeProperty.Species): return (<SpeciesInItalic species={value} />);
    case (DisplayableTreeProperty.ScientificName): return (<ScientificNameInItalic scientificName={value} />);
    default: return (<></>);
    }
  };

  return Tree.isItalicProperty(property)
    ? (
      <span
        className={styles.columnValue}
        title={value as string || ''}
        style={isSelected ? { color: `rgb(${color})` } : {}}
      >
        {getComponent(value as string)}
      </span>
    ) : (
      <span
        className={styles.columnValue + ' bg-outerspace-700'}
        style={isSelected ? { color: `rgb(${color})` } : {}}
      >
        {typeof value === 'number' ? new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(value) : value || '-'}
      </span>
    );
}

interface TableCellProps {
  property: Property,
  value: string | number | Array<{ name: string }> | boolean,
  isSelected: boolean,
  color: string,
  tree: FilteredPartialTree
}
