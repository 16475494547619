import { TreeFilter } from '../tree-filter/TreeFilter';
import { MercatorCoordinate } from '../routes/Explore/MercatorCoordinate';
import { ManagedAreaDto } from '../managed-area/ManagedAreaDto';
import { ManagedArea } from '../managed-area/ManagedArea';
import { TreeImage } from './TreeImage';
import { ThresholdMatchingState } from '../routes/Explore/tree-marker/ThresholdMatchingState';
import { Organization } from '../organization/Organization';
import { Account } from '../account/Account';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { TreeEnvironment } from './DetailedTree';
import { TFunction } from 'react-i18next';
import { CrossSectionalShape } from '../property-enums/CrossSectionalShape';
import TaskTemplate from '../routes/TaskManager/create/TaskTemplate';
import { VitalityVigor } from '../property-enums/VitalityVigor';
import { TreeStatus } from '../property-enums/TreeStatus';
import { GrowSpace } from '../property-enums/GrowSpace';
import { OverheadUtilities } from '../property-enums/OverheadUtilities';
import { CardinalDirection } from '../utils/getCardinalDirectionFromAngle';
import { Flippers } from '../switches/Flippers';

export type Longitude = number;
export type Latitude = number;

export enum Fork {
  VSHAPE = 'vShape',
  USHAPE = 'uShape'
}
export enum ViStatus {
  TO_BE_SCHEDULED = 'toBeScheduled',
  TO_DO = 'toDo',
  IN_PROGRESS = 'inProgress',
  ON_HOLD = 'onHold',
  SENT_TO_ON_SITE_VI = 'sentToOnSiteVi',
  ADVANCED_ASSESSMENT_TO_DO = 'advancedAssessmentToDo',
  MITIGATION_TO_DO = 'mitigationToDo',
  ESCALATED = 'escalated'
}

export enum Condition {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  FAIR = 'fair',
  POOR = 'poor',
  CRITICAL = 'critical',
  DEAD = 'dead'
}

export enum CrownTransparency {
  NOT_DEFOLIATED = 'notDefoliated',
  SLIGHTLY_DEFOLIATED = 'slightlyDefoliated',
  MODERATELY_DEFOLIATED = 'moderatelyDefoliated',
  SEVERELY_DEFOLIATED = 'severelyDefoliated',
  DEAD = 'dead'
}

export enum DisplayableTreeProperty {
  Height = 'height',
  TrunkHeight = 'trunkHeight',
  CanopyHeight = 'canopyHeight',
  CanopyWidth = 'canopyWidth',
  TrunkCircumference = 'trunkCircumference',
  CanopyCircumference = 'canopyCircumference',
  TrunkDiameter = 'trunkDiameter',
  Evaporation = 'evaporation',
  WaterIntercepted = 'waterIntercepted',
  AvoidedRunoff = 'avoidedRunoff',
  AvoidedRunoffEcoValue = 'avoidedRunoffEcoValue',

  LeafArea = 'leafArea',
  LeafBiomass = 'leafBiomass',
  LeafAreaIndex = 'leafAreaIndex',
  NDVI = 'ndvi',
  TreeHealth = 'treeHealth',
  CarbonStorage = 'carbonStorage',
  CarbonStorageEcoValue = 'carbonStorageEcoValue',
  GrossCarbonSequestration = 'grossCarbonSequestration',
  GrossCarbonSequestrationEcoValue = 'grossCarbonSequestrationEcoValue',
  NO2 = 'no2',
  NO2EcoValue = 'no2EcoValue',
  SO2 = 'so2',
  SO2EcoValue = 'so2EcoValue',
  PM25 = 'pm25',
  PM25EcoValue = 'pm25EcoValue',
  CO = 'co',
  COEcoValue = 'coEcoValue',
  O3 = 'o3',
  O3EcoValue = 'o3EcoValue',
  PotentialEvapotranspiration = 'potentialEvapotranspiration',
  Transpiration = 'transpiration',
  OxygenProduction = 'oxygenProduction',

  SafetyFactorAt80Kmh = 'safetyFactorAt80Kmh',
  SafetyFactorAtDefaultWindSpeed = 'safetyFactorAtDefaultWindSpeed',
  SafetyFactors = 'safetyFactors',

  ThermalComfort = 'thermalComfort',
  LeaningAngle = 'leaningAngle',

  TreeValueCavat = 'treeValueCavat',
  TreeValueKoch = 'treeValueKoch',
  TreeValueRado = 'treeValueRado',

  Dieback = 'dieback',
  DeadBranchesRatio = 'deadBranchesRatio',

  Status = 'status',
  VitalityVigor = 'vitalityVigor',
  CrownLightExposure = 'crownLightExposure',
  LeafAreaPerCrownVolume = 'leafAreaPerCrownVolume',
  LiveCrownRatio = 'liveCrownRatio',
  Slenderness = 'slenderness',

  ViStatus = 'viStatus',

  HasViObservation = 'hasViObservation',
  HasMitigation = 'hasMitigation',
  HasAssessmentRequest = 'hasAssessmentRequest',

  ViObservations = 'viObservations',
  Mitigations = 'mitigations',
  AssessmentRequests = 'assessmentRequests',

  viObservations = 'viObservations',

  PlantingYear = 'plantingYear',
  NumberOfStems = 'numberOfStems',
  Age = 'age',
  CustomerTreeId = 'customerTreeId',
  CustomerTagId = 'customerTagId',
  CustomerSiteId = 'customerSiteId',
  LocalizedLocation = 'localizedLocation',
  Owner = 'owner',
  OnStreetName = 'onStreetName',
  SideLocation = 'sideLocation',
  GrowSpaceSize = 'growSpaceSize',
  OverheadUtilities = 'overheadUtilities',
  ParkName = 'parkName',
  GrowSpace = 'growSpace',
  LandUse = 'landUse',

  Genus = 'genus',
  Species = 'species',
  CommonName = 'commonName',
  StreetAddress = 'streetAddress',

  LimbDiameter = 'limbDiameter',
  CoDominantStems = 'coDominantStems',
  IncludedBark = 'includedBark',
  TmsCategory = 'tmsCategory',
  AgeClass = 'ageClass',
  CultivarOrVariety = 'cultivarOrVariety',
  Cultivar = 'cultivar',
  Infraspecies = 'infraspecies',
  AgeAtPlanting = 'ageAtPlanting',
  CriticalRootZone = 'criticalRootZone',
  StructuralCriticalRootZone = 'structuralCriticalRootZone',
  AddressFromParcel = 'addressFromParcel',
  CrownVolume = 'crownVolume',

  FoliageNoneSeasonal = 'foliageNoneSeasonal',
  FoliageNoneDead = 'foliageNoneDead',
  NormalFoliage = 'normalFoliage',
  ChloroticFoliage = 'chloroticFoliage',
  NecroticFoliage = 'necroticFoliage',

  ScientificName = 'scientificName',
  ManagedAreaId = 'managedAreaId',
  ExternalId = 'externalId',
  TrunkWidth = 'trunkWidth',
  Condition = 'condition',
  PotentialTargets = 'potentialTargets',
  NumberOfLimbs = 'numberOfLimbs',

  AbsoluteWeakestPoint = 'absoluteWeakestPoint',
  CriticalWindSpeed = 'criticalWindSpeed',
  FurtherInspectionNeeded = 'furtherInspectionNeeded',
  CrownTransparency = 'crownTransparency',

  OutlierHeightPerCrownVolume = 'outlierHeightPerCrownVolume',
  OutlierHeightPerLeafArea = 'outlierHeightPerLeafArea',
  OutlierLeafAreaPerCrownVolume = 'outlierLeafAreaPerCrownVolume',
  OutlierTrunkDiameterPerCrownVolume = 'outlierTrunkDiameterPerCrownVolume',
  OutlierTrunkDiameterPerHeight = 'outlierTrunkDiameterPerHeight',
  OutlierTrunkDiameterPerLeafArea = 'outlierTrunkDiameterPerLeafArea',
  OverallOutlierIndex = 'overallOutlierIndex',

  WireClearanceIssueDetected = 'wireClearanceIssueDetected',
  RoadClearanceIssueDetected = 'roadClearanceIssueDetected',
  BuildingClearanceIssueDetected = 'buildingClearanceIssueDetected',
  VisibilityClearanceIssueDetected = 'visibilityClearanceIssueDetected',
  TrafficSignClearanceIssueDetected = 'trafficSignClearanceIssueDetected',

  DisplayableWorldCoordinates = 'displayableWorldCoordinates',

  RecordingDate = 'recordingDate',
  LastUpdatedAt = 'lastUpdatedAt',

  PrevailingWindDirection = 'prevailingWindDirection',
  Fork = 'fork'
}

export class Tree {
  static readonly SAFETY_FACTOR_THRESHOLD = 1.5;
  static readonly DEFAULT_WIND_SPEED = 80;

  private static readonly ENUM_PROPERTY_OPTIONS_MAP = new Map([
    [DisplayableTreeProperty.Status, Object.values(TreeStatus)],
    [DisplayableTreeProperty.VitalityVigor, Object.values(VitalityVigor)],
    [DisplayableTreeProperty.WireClearanceIssueDetected, ['true', 'false']],
    [DisplayableTreeProperty.RoadClearanceIssueDetected, ['true', 'false']],
    [DisplayableTreeProperty.OverallOutlierIndex, new Array(7).fill(0).map((_, i) => i.toString())],
    [DisplayableTreeProperty.AgeClass, ['young', 'mature_veteran']],
    [DisplayableTreeProperty.ViStatus, Object.values(ViStatus)],
    [DisplayableTreeProperty.Condition, Object.values(Condition)],
    [DisplayableTreeProperty.CrownTransparency, Object.values(CrownTransparency)],
    [DisplayableTreeProperty.CrownLightExposure, new Array(5).fill(0).map((_, i) => i.toString())],
    [DisplayableTreeProperty.OutlierHeightPerCrownVolume, ['true', 'false']],
    [DisplayableTreeProperty.OutlierHeightPerLeafArea, ['true', 'false']],
    [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume, ['true', 'false']],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume, ['true', 'false']],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight, ['true', 'false']],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea, ['true', 'false']],
    [DisplayableTreeProperty.GrowSpace, Object.values(GrowSpace)],
    [DisplayableTreeProperty.OverheadUtilities, Object.values(OverheadUtilities)],
    [DisplayableTreeProperty.PrevailingWindDirection, Object.values(CardinalDirection)],
    [DisplayableTreeProperty.CoDominantStems, ['true', 'false']],
    [DisplayableTreeProperty.Fork, Object.values(Fork)],
    [DisplayableTreeProperty.FoliageNoneSeasonal, ['true', 'false']],
    [DisplayableTreeProperty.FoliageNoneDead, ['true', 'false']],
    [DisplayableTreeProperty.IncludedBark, ['true', 'false']]
  ]);

  private static readonly UNIT_MAP = new Map([
    [DisplayableTreeProperty.Height, 'm'],
    [DisplayableTreeProperty.TrunkHeight, 'm'],
    [DisplayableTreeProperty.CanopyHeight, 'm'],
    [DisplayableTreeProperty.CanopyWidth, 'm'],
    [DisplayableTreeProperty.TrunkCircumference, 'm'],
    [DisplayableTreeProperty.CanopyCircumference, 'm'],
    [DisplayableTreeProperty.LeafArea, 'm²'],
    [DisplayableTreeProperty.LeafBiomass, 'kg'],
    [DisplayableTreeProperty.LeafAreaIndex, ''],
    [DisplayableTreeProperty.CarbonStorage, 'kg'],
    [DisplayableTreeProperty.CarbonStorageEcoValue, '€'],
    [DisplayableTreeProperty.GrossCarbonSequestration, 'kg/yr'],
    [DisplayableTreeProperty.GrossCarbonSequestrationEcoValue, '€'],
    [DisplayableTreeProperty.NO2, 'g/yr'],
    [DisplayableTreeProperty.NO2EcoValue, '€'],
    [DisplayableTreeProperty.SO2, 'g/yr'],
    [DisplayableTreeProperty.SO2EcoValue, '€'],
    [DisplayableTreeProperty.PM25, 'g/yr'],
    [DisplayableTreeProperty.PM25EcoValue, '€'],
    [DisplayableTreeProperty.CO, 'g/yr'],
    [DisplayableTreeProperty.COEcoValue, '€'],
    [DisplayableTreeProperty.O3, 'g/yr'],
    [DisplayableTreeProperty.O3EcoValue, '€'],
    [DisplayableTreeProperty.NDVI, ''],
    [DisplayableTreeProperty.TreeHealth, ''],
    [DisplayableTreeProperty.PotentialEvapotranspiration, 'm³/yr'],
    [DisplayableTreeProperty.Transpiration, 'm³/yr'],
    [DisplayableTreeProperty.OxygenProduction, 'kg/yr'],
    [DisplayableTreeProperty.TrunkDiameter, 'm'],
    [DisplayableTreeProperty.AvoidedRunoff, 'm³/yr'],
    [DisplayableTreeProperty.AvoidedRunoffEcoValue, '€'],
    [DisplayableTreeProperty.Evaporation, 'm³/yr'],
    [DisplayableTreeProperty.WaterIntercepted, 'm³/yr'],
    [DisplayableTreeProperty.ThermalComfort, '°C'],
    [DisplayableTreeProperty.TreeValueCavat, '£'],
    [DisplayableTreeProperty.TreeValueKoch, '€'],
    [DisplayableTreeProperty.TreeValueRado, 'Ft'],
    [DisplayableTreeProperty.LeaningAngle, '°'],
    [DisplayableTreeProperty.Dieback, '%'],
    [DisplayableTreeProperty.DeadBranchesRatio, '%'],
    [DisplayableTreeProperty.Slenderness, '%'],
    [DisplayableTreeProperty.LiveCrownRatio, '%'],
    [DisplayableTreeProperty.LimbDiameter, 'cm'],
    [DisplayableTreeProperty.NormalFoliage, '%'],
    [DisplayableTreeProperty.ChloroticFoliage, '%'],
    [DisplayableTreeProperty.NecroticFoliage, '%'],
    [DisplayableTreeProperty.StructuralCriticalRootZone, 'm'],
    [DisplayableTreeProperty.CriticalRootZone, 'm'],
    [DisplayableTreeProperty.TrunkWidth, 'm'],
    [DisplayableTreeProperty.CrownVolume, 'm³'],
    [DisplayableTreeProperty.AbsoluteWeakestPoint, 'm'],
    [DisplayableTreeProperty.CriticalWindSpeed, 'km/h'],
    ['clearanceVolume', 'm³'],
    ['clearanceVolumeRatio', '%'],
    ['minimumDistance', 'm'],
    ['minimumHeight', 'm']
  ]);

  private static readonly IMPERIAL_UNIT_MAP = new Map([
    [DisplayableTreeProperty.Height, 'ft'],
    [DisplayableTreeProperty.TrunkHeight, 'ft'],
    [DisplayableTreeProperty.CanopyHeight, 'ft'],
    [DisplayableTreeProperty.CanopyWidth, 'ft'],
    [DisplayableTreeProperty.TrunkCircumference, 'in'],
    [DisplayableTreeProperty.CanopyCircumference, 'ft'],
    [DisplayableTreeProperty.LeafArea, 'sq ft'],
    [DisplayableTreeProperty.LeafBiomass, 'lb'],
    [DisplayableTreeProperty.LeafAreaIndex, ''],
    [DisplayableTreeProperty.CarbonStorage, 'lb'],
    [DisplayableTreeProperty.CarbonStorageEcoValue, '€'],
    [DisplayableTreeProperty.GrossCarbonSequestration, 'lb/yr'],
    [DisplayableTreeProperty.GrossCarbonSequestrationEcoValue, '€'],
    [DisplayableTreeProperty.NO2, 'lb/yr'],
    [DisplayableTreeProperty.NO2EcoValue, '€'],
    [DisplayableTreeProperty.SO2, 'lb/yr'],
    [DisplayableTreeProperty.SO2EcoValue, '€'],
    [DisplayableTreeProperty.PM25, 'lb/yr'],
    [DisplayableTreeProperty.PM25EcoValue, '€'],
    [DisplayableTreeProperty.CO, 'lb/yr'],
    [DisplayableTreeProperty.COEcoValue, '€'],
    [DisplayableTreeProperty.O3, 'lb/yr'],
    [DisplayableTreeProperty.O3EcoValue, '€'],
    [DisplayableTreeProperty.NDVI, ''],
    [DisplayableTreeProperty.TreeHealth, ''],
    [DisplayableTreeProperty.PotentialEvapotranspiration, 'ft³/yr'],
    [DisplayableTreeProperty.Transpiration, 'ft³/yr'],
    [DisplayableTreeProperty.OxygenProduction, 'lb/yr'],
    [DisplayableTreeProperty.TrunkDiameter, 'in'],
    [DisplayableTreeProperty.AvoidedRunoff, 'ft³/yr'],
    [DisplayableTreeProperty.AvoidedRunoffEcoValue, '€'],
    [DisplayableTreeProperty.Evaporation, 'ft³/yr'],
    [DisplayableTreeProperty.WaterIntercepted, 'ft³/yr'],
    [DisplayableTreeProperty.ThermalComfort, '°F'],
    [DisplayableTreeProperty.TreeValueCavat, '£'],
    [DisplayableTreeProperty.TreeValueKoch, '€'],
    [DisplayableTreeProperty.TreeValueRado, 'Ft'],
    [DisplayableTreeProperty.LeaningAngle, '°'],
    [DisplayableTreeProperty.Dieback, '%'],
    [DisplayableTreeProperty.DeadBranchesRatio, '%'],
    [DisplayableTreeProperty.Slenderness, '%'],
    [DisplayableTreeProperty.LiveCrownRatio, '%'],
    [DisplayableTreeProperty.LimbDiameter, 'in'],
    [DisplayableTreeProperty.NormalFoliage, '%'],
    [DisplayableTreeProperty.ChloroticFoliage, '%'],
    [DisplayableTreeProperty.NecroticFoliage, '%'],
    [DisplayableTreeProperty.StructuralCriticalRootZone, 'ft'],
    [DisplayableTreeProperty.CriticalRootZone, 'ft'],
    [DisplayableTreeProperty.TrunkWidth, 'in'],
    [DisplayableTreeProperty.CrownVolume, 'ft³'],
    [DisplayableTreeProperty.AbsoluteWeakestPoint, 'ft'],
    [DisplayableTreeProperty.CriticalWindSpeed, 'mph'],
    ['clearanceVolume', 'ft³'],
    ['clearanceVolumeRatio', '%'],
    ['minimumDistance', 'ft'],
    ['minimumHeight', 'ft']
  ]);

  static readonly INFO_PROPERTIES = [
    DisplayableTreeProperty.CultivarOrVariety,
    DisplayableTreeProperty.Infraspecies,
    DisplayableTreeProperty.Cultivar,
    DisplayableTreeProperty.CustomerSiteId,
    DisplayableTreeProperty.CustomerTagId,
    DisplayableTreeProperty.CustomerTreeId,
    DisplayableTreeProperty.Owner,
    DisplayableTreeProperty.PlantingYear,
    DisplayableTreeProperty.DisplayableWorldCoordinates
  ];

  static readonly ASSIGNMENT_PROPERTIES = [
    DisplayableTreeProperty.AgeClass,
    DisplayableTreeProperty.AddressFromParcel,
    DisplayableTreeProperty.CommonName,
    DisplayableTreeProperty.Genus,
    DisplayableTreeProperty.ParkName,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.StreetAddress,
    DisplayableTreeProperty.TmsCategory
  ];

  static readonly BENEFIT_PROPERTIES = [
    DisplayableTreeProperty.AvoidedRunoff,
    DisplayableTreeProperty.GrossCarbonSequestration,
    DisplayableTreeProperty.CarbonStorage,
    DisplayableTreeProperty.TreeValueCavat,
    DisplayableTreeProperty.CO,
    DisplayableTreeProperty.Evaporation,
    DisplayableTreeProperty.NO2,
    DisplayableTreeProperty.O3,
    DisplayableTreeProperty.OxygenProduction,
    DisplayableTreeProperty.PM25,
    DisplayableTreeProperty.PotentialEvapotranspiration,
    DisplayableTreeProperty.SO2,
    DisplayableTreeProperty.Transpiration,
    DisplayableTreeProperty.WaterIntercepted
  ];

  static readonly CONCLUSION_PROPERTIES = [
    DisplayableTreeProperty.FurtherInspectionNeeded,
    DisplayableTreeProperty.ViStatus
  ];

  static readonly DATES = [
    DisplayableTreeProperty.LastUpdatedAt,
    DisplayableTreeProperty.RecordingDate
  ];

  static readonly DIMENSION_PROPERTIES = [
    DisplayableTreeProperty.Height,
    DisplayableTreeProperty.TrunkHeight,
    DisplayableTreeProperty.CanopyHeight,
    DisplayableTreeProperty.CanopyWidth,
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.TrunkDiameter,
    DisplayableTreeProperty.CanopyCircumference,
    DisplayableTreeProperty.CrownVolume,
    DisplayableTreeProperty.LeaningAngle,
    DisplayableTreeProperty.NumberOfStems,
    DisplayableTreeProperty.StructuralCriticalRootZone,
    DisplayableTreeProperty.CriticalRootZone
  ];

  static readonly HEALTH_AND_VITALITY_PROPERTIES = [
    DisplayableTreeProperty.Condition,
    DisplayableTreeProperty.CrownTransparency,
    DisplayableTreeProperty.Dieback,
    DisplayableTreeProperty.DeadBranchesRatio,
    DisplayableTreeProperty.LeafArea,
    DisplayableTreeProperty.LeafAreaIndex,
    DisplayableTreeProperty.LeafBiomass,
    DisplayableTreeProperty.NDVI
  ];

  static readonly LOAD_FACTOR_PROPERTIES = [
    DisplayableTreeProperty.CrownLightExposure
  ];

  static readonly SITE_FACTOR_PROPERTIES = [
    DisplayableTreeProperty.GrowSpaceSize,
    DisplayableTreeProperty.GrowSpace,
    DisplayableTreeProperty.LandUse,
    DisplayableTreeProperty.OnStreetName,
    DisplayableTreeProperty.OverheadUtilities,
    DisplayableTreeProperty.PrevailingWindDirection
  ];

  static readonly STRUCTURAL_PROPERTIES = [
    DisplayableTreeProperty.CoDominantStems,
    DisplayableTreeProperty.Fork,
    DisplayableTreeProperty.IncludedBark,
    DisplayableTreeProperty.Slenderness,
    DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed,
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor
  ];

  static readonly HEALTH_AND_SPECIES_PROPERTIES = [
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor
  ];

  static readonly METRICAL_PROPERTIES = [
    DisplayableTreeProperty.Height,
    DisplayableTreeProperty.TrunkHeight,
    DisplayableTreeProperty.CanopyHeight,
    DisplayableTreeProperty.CanopyWidth,
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.TrunkDiameter,
    DisplayableTreeProperty.CanopyCircumference,
    DisplayableTreeProperty.CrownLightExposure,
    DisplayableTreeProperty.TmsCategory,
    DisplayableTreeProperty.DisplayableWorldCoordinates,
    DisplayableTreeProperty.StreetAddress,
    DisplayableTreeProperty.AgeClass,
    DisplayableTreeProperty.StructuralCriticalRootZone,
    DisplayableTreeProperty.CriticalRootZone,
    DisplayableTreeProperty.CrownVolume,
    DisplayableTreeProperty.CultivarOrVariety,
    DisplayableTreeProperty.Cultivar,
    DisplayableTreeProperty.Infraspecies,
    DisplayableTreeProperty.CustomerTreeId,
    DisplayableTreeProperty.CustomerTagId,
    DisplayableTreeProperty.CustomerSiteId,
    DisplayableTreeProperty.PlantingYear,
    DisplayableTreeProperty.ViStatus,
    DisplayableTreeProperty.NumberOfStems,
    DisplayableTreeProperty.Condition,
    DisplayableTreeProperty.CrownTransparency,
    DisplayableTreeProperty.GrowSpace,
    DisplayableTreeProperty.OverheadUtilities,
    DisplayableTreeProperty.PrevailingWindDirection,
    DisplayableTreeProperty.CoDominantStems,
    DisplayableTreeProperty.Fork,
    DisplayableTreeProperty.Owner,
    DisplayableTreeProperty.AddressFromParcel,
    DisplayableTreeProperty.CommonName,
    DisplayableTreeProperty.ParkName,
    DisplayableTreeProperty.OnStreetName,
    DisplayableTreeProperty.GrowSpaceSize,
    DisplayableTreeProperty.FurtherInspectionNeeded,
    DisplayableTreeProperty.RecordingDate,
    DisplayableTreeProperty.LastUpdatedAt,
    DisplayableTreeProperty.LeafBiomass
  ];

  static readonly ECOSYSTEM_PROPERTIES = [
    DisplayableTreeProperty.CarbonStorage,
    DisplayableTreeProperty.GrossCarbonSequestration,
    DisplayableTreeProperty.NO2,
    DisplayableTreeProperty.SO2,
    DisplayableTreeProperty.PM25,
    DisplayableTreeProperty.CO,
    DisplayableTreeProperty.O3,
    DisplayableTreeProperty.PotentialEvapotranspiration,
    DisplayableTreeProperty.Transpiration,
    DisplayableTreeProperty.OxygenProduction,
    DisplayableTreeProperty.AvoidedRunoff,
    DisplayableTreeProperty.Evaporation,
    DisplayableTreeProperty.WaterIntercepted,
    DisplayableTreeProperty.ThermalComfort
  ];

  static readonly HEALTH_INDICATIONS = [
    DisplayableTreeProperty.LeafArea,
    DisplayableTreeProperty.LeafAreaIndex,
    DisplayableTreeProperty.NDVI,
    DisplayableTreeProperty.TreeHealth,
    DisplayableTreeProperty.Dieback,
    DisplayableTreeProperty.DeadBranchesRatio,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.LeafAreaPerCrownVolume,
    DisplayableTreeProperty.LiveCrownRatio,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.IncludedBark
  ];

  static readonly SAFETY_PROPERTIES = [
    DisplayableTreeProperty.SafetyFactors,
    DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed,
    DisplayableTreeProperty.LeaningAngle,
    DisplayableTreeProperty.Slenderness
  ];

  static readonly ECONOMICAL_VALUE = [
    DisplayableTreeProperty.TreeValueCavat,
    DisplayableTreeProperty.TreeValueKoch,
    DisplayableTreeProperty.TreeValueRado
  ];

  static readonly ECONOMICAL_PROPERTY_VALUE = [
    DisplayableTreeProperty.CarbonStorageEcoValue,
    DisplayableTreeProperty.GrossCarbonSequestrationEcoValue,
    DisplayableTreeProperty.NO2EcoValue,
    DisplayableTreeProperty.SO2EcoValue,
    DisplayableTreeProperty.PM25EcoValue,
    DisplayableTreeProperty.COEcoValue,
    DisplayableTreeProperty.O3EcoValue,
    DisplayableTreeProperty.AvoidedRunoffEcoValue
  ];

  static readonly INSPECTIONS = [
    DisplayableTreeProperty.ViStatus
  ];

  static readonly ENUM_PROPERTIES = [
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.ViStatus,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.HasViObservation,
    DisplayableTreeProperty.HasMitigation,
    DisplayableTreeProperty.HasAssessmentRequest,
    DisplayableTreeProperty.ViObservations,
    DisplayableTreeProperty.Mitigations,
    DisplayableTreeProperty.AssessmentRequests,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.ManagedAreaId,
    DisplayableTreeProperty.ScientificName,
    DisplayableTreeProperty.Genus,
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
    DisplayableTreeProperty.OverallOutlierIndex,
    DisplayableTreeProperty.WireClearanceIssueDetected,
    DisplayableTreeProperty.RoadClearanceIssueDetected,
    DisplayableTreeProperty.BuildingClearanceIssueDetected,
    DisplayableTreeProperty.VisibilityClearanceIssueDetected,
    DisplayableTreeProperty.TrafficSignClearanceIssueDetected,
    DisplayableTreeProperty.TmsCategory,
    DisplayableTreeProperty.AgeClass,
    DisplayableTreeProperty.Condition,
    DisplayableTreeProperty.CrownTransparency,
    DisplayableTreeProperty.CrownLightExposure,
    DisplayableTreeProperty.GrowSpace,
    DisplayableTreeProperty.OverheadUtilities,
    DisplayableTreeProperty.PrevailingWindDirection,
    DisplayableTreeProperty.CoDominantStems,
    DisplayableTreeProperty.Fork,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.IncludedBark
  ];

  static readonly SINGLE_SELECT_PROPERTIES = [
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
    DisplayableTreeProperty.WireClearanceIssueDetected,
    DisplayableTreeProperty.RoadClearanceIssueDetected,
    DisplayableTreeProperty.BuildingClearanceIssueDetected,
    DisplayableTreeProperty.VisibilityClearanceIssueDetected,
    DisplayableTreeProperty.TrafficSignClearanceIssueDetected,
    DisplayableTreeProperty.CoDominantStems,
    DisplayableTreeProperty.Fork,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.IncludedBark
  ];

  static readonly STRING_PROPERTIES = [
    DisplayableTreeProperty.CustomerTreeId,
    DisplayableTreeProperty.CustomerTagId,
    DisplayableTreeProperty.CultivarOrVariety,
    DisplayableTreeProperty.Cultivar,
    DisplayableTreeProperty.Infraspecies,
    DisplayableTreeProperty.CustomerSiteId,
    DisplayableTreeProperty.Owner,
    DisplayableTreeProperty.AddressFromParcel,
    DisplayableTreeProperty.CommonName,
    DisplayableTreeProperty.ParkName,
    DisplayableTreeProperty.StreetAddress,
    DisplayableTreeProperty.OnStreetName,
    DisplayableTreeProperty.GrowSpaceSize
  ];

  static readonly DATE_PROPERTIES = [
    DisplayableTreeProperty.FurtherInspectionNeeded,
    DisplayableTreeProperty.RecordingDate,
    DisplayableTreeProperty.LastUpdatedAt
  ];

  static readonly IDENTIFICATIONS = [
    DisplayableTreeProperty.Genus,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.ScientificName
  ];

  static readonly TRANSLATABLE_ENUM_PROPERTIES = this.ENUM_PROPERTIES.filter(it =>
    ![
      DisplayableTreeProperty.Species,
      DisplayableTreeProperty.ManagedAreaId,
      DisplayableTreeProperty.ScientificName,
      DisplayableTreeProperty.Genus,
      DisplayableTreeProperty.OverallOutlierIndex,
      DisplayableTreeProperty.WireClearanceIssueDetected,
      DisplayableTreeProperty.RoadClearanceIssueDetected,
      DisplayableTreeProperty.BuildingClearanceIssueDetected,
      DisplayableTreeProperty.VisibilityClearanceIssueDetected,
      DisplayableTreeProperty.TrafficSignClearanceIssueDetected,
      DisplayableTreeProperty.TmsCategory,
      DisplayableTreeProperty.CrownLightExposure
    ].includes(it)
  );

  static readonly ITALIC_PROPERTIES: string[] = [
    DisplayableTreeProperty.ScientificName,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.Genus
  ];

  static readonly OUTLIER_PROPERTIES = [
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
    DisplayableTreeProperty.OverallOutlierIndex
  ];

  static readonly CLEARANCE_PROPERTIES = [
    DisplayableTreeProperty.WireClearanceIssueDetected,
    DisplayableTreeProperty.RoadClearanceIssueDetected,
    DisplayableTreeProperty.BuildingClearanceIssueDetected,
    DisplayableTreeProperty.VisibilityClearanceIssueDetected,
    DisplayableTreeProperty.TrafficSignClearanceIssueDetected
  ];

  static getUnit(property: string, organization: Organization): string {
    if ([
      'externalId', 'species', 'managedAreaId',
      DisplayableTreeProperty.SafetyFactors,
      DisplayableTreeProperty.Slenderness
    ].includes(property)) {
      return '';
    }

    const unitMap = organization.isMetric ? Tree.UNIT_MAP : Tree.IMPERIAL_UNIT_MAP;
    if (this.ECONOMICAL_PROPERTY_VALUE.includes(property as DisplayableTreeProperty)) return organization.currency;
    return unitMap.get(property as DisplayableTreeProperty) ?? '';
  }

  static getWindSpeedUnit(organization: Organization): string {
    return organization.getIsMetrical() ? 'km/h' : 'mph';
  }

  static renderPropertyValue(property: string, value: any, t: TFunction): string {
    const locale = navigator.language;

    if (value === null || value === undefined) return '-';

    if (Tree.isBooleanProperty(property) || Tree.isEnumProperty(property)) {
      return t(Tree.getTKeyForProperty(property, value));
    }

    if (Tree.isDateProperty(property)) {
      return new Date(value).toLocaleDateString();
    }

    if (typeof value === 'number') {
      return new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(value);
    }

    return value;
  }

  static renderPropertyName(property: string, t: TFunction, organization: Organization): string {
    if (property === DisplayableTreeProperty.CustomerTreeId && organization.isEnabled(Flippers.davey)) return t('details.properties.matchedSiteId');
    return t('tree.' + property, { defaultValue: t('details.properties.' + property) });
  }

  static getTKeyForProperty(property: string, value?: string): string {
    if (Tree.isClearanceBooleanProperty(property)) {
      return 'details.properties.clearances.values.' + value;
    }
    if (Tree.isBooleanProperty(property) || (Tree.isViBooleanProperty(property) && ((value === 'true' || value === 'false') || typeof value === 'boolean'))) {
      return `details.properties.booleanLabels.${value}`;
    }
    if (property === DisplayableTreeProperty.TmsCategory) return value?.toUpperCase() ?? '-';
    if (!this.isTranslatableProperty(property) && (value !== undefined && value !== null)) return value;
    if (value) {
      if (property === DisplayableTreeProperty.ViStatus) {
        return `virtualInspection.status.${value}`;
      }
      if (property === DisplayableTreeProperty.Status) {
        return value ? `tree.statusTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.VitalityVigor) {
        return value ? `tree.vitalityVigorTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.AgeClass) {
        return value ? `tree.ageClassTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.Condition) {
        return value ? `tree.conditionTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.CrownTransparency) {
        return value ? `tree.crownTransparencyTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.GrowSpace) {
        return value ? `details.properties.growSpaceTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.OverheadUtilities) {
        return value ? `details.properties.overheadUtilitiesTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.PrevailingWindDirection) {
        return value ? `details.properties.prevailingWindDirectionTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.Fork) {
        return value ? `details.properties.forkTypes.${value}` : '-';
      }
    }
    return `tree.${property}`;
  }

  static getDefaultSafetyFactorProperty(account?: Account): string {
    if (!account) return DisplayableTreeProperty.SafetyFactorAt80Kmh;
    return DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed;
  }

  static empty(dto: Partial<TreeDto> = {}): Tree {
    return Tree.fromDto({
      location: { coordinates: [NaN, NaN, NaN] },
      localizedLocation: { coordinates: [NaN, NaN, NaN] },
      managedArea: {},
      wireClearanceIssueDetected: false,
      roadClearanceIssueDetected: false,
      buildingClearanceIssueDetected: false,
      visibilityClearanceIssueDetected: false,
      trafficSignClearanceIssueDetected: false,
      ...dto
    } as TreeDto);
  }

  static fromDto(dto: TreeDto) {
    return new Tree(
      dto.id,
      dto.managedAreaId,
      dto.height,
      dto.trunkHeight,
      dto.trunkWidth,
      dto.trunkEllipseRadiusA,
      dto.trunkEllipseRadiusB,
      dto.trunkCircumference,
      dto.canopyHeight,
      dto.canopyWidth,
      dto.canopyEllipseRadiusA,
      dto.canopyEllipseRadiusB,
      dto.canopyCircumference,
      dto.canopyDirection,
      dto.canopyOffset?.coordinates,

      dto.leafArea,
      dto.leafBiomass,
      dto.leafAreaIndex,
      dto.carbonStorage,
      dto.grossCarbonSequestration,
      dto.no2,
      dto.so2,
      dto.pm25,
      dto.co,
      dto.o3,
      dto.ndvi,
      dto.treeHealth,
      dto.carbonStorageEcoValue,
      dto.grossCarbonSequestrationEcoValue,
      dto.no2EcoValue,
      dto.so2EcoValue,
      dto.pm25EcoValue,
      dto.coEcoValue,
      dto.o3EcoValue,
      dto.avoidedRunoffEcoValue,
      dto.potentialEvapotranspiration,
      dto.transpiration,
      dto.oxygenProduction,

      dto.safetyFactorAt80Kmh,
      dto.safetyFactorAtDefaultWindSpeed,
      dto.location.coordinates,
      dto.safetyFactors,
      dto.externalId,
      dto.customerTreeId,
      dto.customerTagId,
      dto.customerSiteId,

      dto.localizedLocation.coordinates,
      dto.images,
      ManagedArea.fromDto(dto.managedArea),
      dto.trunkDiameter,
      dto.genus,
      dto.species,
      dto.scientificName,
      dto.pointCloudPath,
      dto.environmentPointCloudPath,

      dto.evaporation,
      dto.waterIntercepted,
      dto.avoidedRunoff,
      dto.leaningVector?.coordinates ?? [NaN, NaN, NaN],
      dto.leaningAngle,

      dto.treeValueCavat,
      dto.treeValueKoch,
      dto.treeValueRado,
      dto.thermalComfort,
      dto.capturePointId,
      dto.dieback,
      dto.deadBranchesRatio,
      dto.cohort,

      dto.status,
      dto.vitalityVigor,
      dto.crownLightExposure,
      dto.leafAreaPerCrownVolume,
      dto.liveCrownRatio,
      dto.slenderness,
      dto.viStatus,
      dto.mitigations,
      dto.cultivarOrVariety,
      dto.cultivar,
      dto.infraspecies,
      dto.tmsCategory,
      dto.ageClass,
      dto.ageAtPlanting,
      dto.includedBark,
      dto.vat19,
      dto.alnarpModel,
      dto.normaGranada,
      dto.limbs,
      dto.coDominantLimbs,
      dto.fork,
      dto.crossSectionalShape,
      dto.foliageNoneSeasonal,
      dto.foliageNoneDead,
      dto.normalFoliage,
      dto.chloroticFoliage,
      dto.necroticFoliage,
      dto.crownVolume,
      dto.criticalRootZone,
      dto.structuralCriticalRootZone,
      dto.userUpdatedProperties,
      dto.outlierHeightPerCrownVolume,
      dto.outlierHeightPerLeafArea,
      dto.outlierLeafAreaPerCrownVolume,
      dto.outlierTrunkDiameterPerCrownVolume,
      dto.outlierTrunkDiameterPerHeight,
      dto.outlierTrunkDiameterPerLeafArea,
      dto.overallOutlierIndex,
      dto.aggByClassWireClearances,
      dto.lastUpdatedAt,
      dto.wireClearanceIssueDetected?.toString(),
      dto.roadClearanceIssueDetected?.toString(),
      dto.buildingClearanceIssueDetected?.toString(),
      dto.visibilityClearanceIssueDetected?.toString(),
      dto.trafficSignClearanceIssueDetected?.toString(),
      dto.streetAddress,
      dto.plantingYear,
      dto.condition,
      dto.crownTransparency,
      dto.growSpace,
      dto.overheadUtilities,
      dto.prevailingWindDirection,
      dto.coDominantStems,
      dto.recordingDate
    );
  }

  static isTranslatableProperty(property: string): boolean {
    return this.TRANSLATABLE_ENUM_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isEnumProperty(property: string): boolean {
    return this.ENUM_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isSingleSelectProperty(property: string): boolean {
    return this.SINGLE_SELECT_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isMultiSelectProperty(property: string): boolean {
    return Tree.isEnumProperty(property) && !Tree.isSingleSelectProperty(property);
  }

  static isStringProperty(property: string): boolean {
    return this.STRING_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isDateProperty(property: string): boolean {
    return this.DATE_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isItalicProperty(property: string): boolean {
    return this.ITALIC_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isViBooleanProperty(property: string | DisplayableTreeProperty): boolean {
    return [
      DisplayableTreeProperty.HasViObservation,
      DisplayableTreeProperty.HasMitigation,
      DisplayableTreeProperty.HasAssessmentRequest
    ].includes(property as DisplayableTreeProperty);
  }

  static isClearanceBooleanProperty(property: string): boolean {
    return [
      DisplayableTreeProperty.WireClearanceIssueDetected,
      DisplayableTreeProperty.RoadClearanceIssueDetected,
      DisplayableTreeProperty.BuildingClearanceIssueDetected,
      DisplayableTreeProperty.VisibilityClearanceIssueDetected,
      DisplayableTreeProperty.TrafficSignClearanceIssueDetected
    ].includes(property as DisplayableTreeProperty);
  }

  static isBooleanProperty(property: string | DisplayableTreeProperty): boolean {
    return [
      DisplayableTreeProperty.OutlierHeightPerCrownVolume,
      DisplayableTreeProperty.OutlierHeightPerLeafArea,
      DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
      DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
      DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
      DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
      DisplayableTreeProperty.CoDominantStems,
      DisplayableTreeProperty.FoliageNoneSeasonal,
      DisplayableTreeProperty.FoliageNoneDead,
      DisplayableTreeProperty.IncludedBark
    ].includes(property as DisplayableTreeProperty);
  }

  static getEnumOptions(property: DisplayableTreeProperty): string[] {
    return this.ENUM_PROPERTY_OPTIONS_MAP.get(property) ?? [];
  }

  constructor(
    readonly id: string,
    readonly managedAreaId: string,
    readonly height: number,
    readonly trunkHeight: number,
    readonly trunkWidth: number,
    readonly trunkEllipseRadiusA: number,
    readonly trunkEllipseRadiusB: number,
    readonly trunkCircumference: number,
    readonly canopyHeight: number,
    readonly canopyWidth: number,
    readonly canopyEllipseRadiusA: number,
    readonly canopyEllipseRadiusB: number,
    readonly canopyCircumference: number,
    readonly canopyDirection: number,
    readonly canopyOffset: [number, number],
    readonly leafArea: number,
    readonly leafBiomass: number,
    readonly leafAreaIndex: number,
    readonly carbonStorage: number,
    readonly grossCarbonSequestration: number,
    readonly no2: number,
    readonly so2: number,
    readonly pm25: number,
    readonly co: number,
    readonly o3: number,
    readonly ndvi: number,
    readonly treeHealth: number,
    readonly carbonStorageEcoValue: number | null,
    readonly grossCarbonSequestrationEcoValue: number | null,
    readonly no2EcoValue: number | null,
    readonly so2EcoValue: number | null,
    readonly pm25EcoValue: number | null,
    readonly coEcoValue: number | null,
    readonly o3EcoValue: number | null,
    readonly avoidedRunoffEcoValue: number | null,
    readonly potentialEvapotranspiration: number,
    readonly transpiration: number,
    readonly oxygenProduction: number,
    readonly safetyFactorAt80Kmh: number | undefined,
    readonly safetyFactorAtDefaultWindSpeed: number | undefined,
    readonly location: [Longitude, Latitude, number],
    readonly safetyFactors: SafetyFactor[],
    readonly externalId: string,
    readonly customerTreeId: string,
    readonly customerTagId: string,
    readonly customerSiteId: string,
    readonly localizedLocation: [number, number, number],
    readonly images: TreeImage[],
    public managedArea: ManagedArea,
    readonly trunkDiameter: number,
    readonly genus: string,
    readonly species: string,
    readonly scientificName: string,
    readonly pointCloudPath: string,
    readonly environmentPointCloudPath: string,
    readonly evaporation: number,
    readonly waterIntercepted: number,
    readonly avoidedRunoff: number,
    readonly leaningVector: [number, number, number],
    readonly leaningAngle: number,
    readonly treeValueCavat: number,
    readonly treeValueKoch: number,
    readonly treeValueRado: number,
    readonly thermalComfort: number,
    readonly capturePointId: string,
    readonly dieback: number | undefined,
    readonly deadBranchesRatio: number | undefined,
    readonly cohort: Cohort | undefined,
    readonly status: string,
    readonly vitalityVigor: string,
    readonly crownLightExposure: number | null,
    readonly leafAreaPerCrownVolume: number | null,
    readonly liveCrownRatio: number | null,
    readonly slenderness: number | null,
    readonly viStatus: ViStatus | undefined,
    readonly mitigations: Mitigation[],
    readonly cultivarOrVariety: string,
    readonly cultivar: string,
    readonly infraspecies: string,
    readonly tmsCategory: string,
    readonly ageClass: string,
    readonly ageAtPlanting: number,
    readonly includedBark: boolean | null,
    readonly vat19: number | null,
    readonly alnarpModel: number | null,
    readonly normaGranada: number | null,
    readonly limbs: { diameter: number }[],
    readonly coDominantLimbs: boolean | null,
    readonly fork: Fork | null,
    readonly crossSectionalShape: CrossSectionalShape | null,
    readonly foliageNoneSeasonal: boolean | null,
    readonly foliageNoneDead: boolean | null,
    readonly normalFoliage: number | null,
    readonly chloroticFoliage: number | null,
    readonly necroticFoliage: number | null,
    readonly crownVolume: number | null,
    readonly criticalRootZone: number | null,
    readonly structuralCriticalRootZone: number | null,
    readonly userUpdatedProperties: string[] = [],
    readonly outlierHeightPerCrownVolume: boolean | null,
    readonly outlierHeightPerLeafArea: boolean | null,
    readonly outlierLeafAreaPerCrownVolume: boolean | null,
    readonly outlierTrunkDiameterPerCrownVolume: boolean | null,
    readonly outlierTrunkDiameterPerHeight: boolean | null,
    readonly outlierTrunkDiameterPerLeafArea: boolean | null,
    readonly overallOutlierIndex: number | null,
    readonly aggByClassWireClearances: any[] = [],
    readonly lastUpdatedAt: string | null,
    readonly wireClearanceIssueDetected: string,
    readonly roadClearanceIssueDetected: string,
    readonly buildingClearanceIssueDetected: string,
    readonly visibilityClearanceIssueDetected: string,
    readonly trafficSignClearanceIssueDetected: string,
    readonly streetAddress: string,
    readonly plantingYear: number,
    readonly condition?: string,
    readonly crownTransparency?: string,
    readonly growSpace?: string,
    readonly overheadUtilities?: string,
    readonly prevailingWindDirection?: CardinalDirection,
    readonly coDominantStems?: boolean,
    readonly recordingDate?: string
  ) {
  }

  get firstBifurcation() {
    return this.trunkHeight;
  }

  get outliers() {
    return Tree.OUTLIER_PROPERTIES.filter(it => this[it] !== null);
  }

  getGenus(organization: Organization): string {
    return this.genus;
  }

  getSpecies(organization: Organization): string {
    return this.species;
  }

  getPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlOfTreeDataFromRelativePath(this.pointCloudPath);
  }

  getEnvironmentPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlOfTreeDataFromRelativePath(this.environmentPointCloudPath);
  }

  getCorridorClearingPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlFromRelativePath('tasks/' + this.managedArea.code + '/tree_segmentation_clips/' + this.externalId + '_road_clearing.laz');
  }

  getWireClearanceFullWireUrl(): string {
    return `/demo/${this.externalId}/All_Cables.gltf`;
  }

  getWireClearanceCollidedWireUrl(): string {
    return `/demo/${this.externalId}/Collided_Cables.gltf`;
  }

  getWindDirectionAngle(windSpeed: number): number | null {
    const safetyFactor = this.safetyFactors.find(it => it.windSpeed === windSpeed);
    if (!safetyFactor) {
      return null;
    }
    return safetyFactor.windDirection;
  }

  getMercatorCoordinates() {
    return new MercatorCoordinate(this.location);
  }

  hasProperty(property: string | null | undefined) {
    if (!property) {
      return false;
    }

    return !!(this[property] || this[property] === 0);
  }

  setManagedArea(managedArea: ManagedArea) {
    this.managedArea = managedArea;
  }

  hasImages() {
    return this.images.length > 0;
  }

  getMainImageURL(): string {
    return this.images.at(0)?.getRotatedUrl() ?? '';
  }

  getMainImageThumbnailURL(): string {
    return this.images.at(0)?.getThumbnailUrl() ?? '';
  }

  async getMainImageThumbnailFromLayersURL(): Promise<string> {
    return await this.images.at(0)?.getThumbnailUrlFromLayers() ?? '';
  }

  getWorldCoordinates(): [number, number] {
    const mercatorCoordinateMin = 20037508.34;
    return [
      (Math.atan(Math.exp((this.location[1] * Math.PI) / mercatorCoordinateMin)) * 360) / Math.PI - 90,
      (this.location[0] * 180) / mercatorCoordinateMin
    ];
  }

  get displayableWorldCoordinates(): string {
    const [x, y] = this.getWorldCoordinates();
    return `${x}, ${y}`;
  }

  getCoordinates() {
    return this.location.slice(0, 2) as [number, number];
  }

  getSafetyFactor(windSpeed: number) {
    return this.safetyFactors.find(it => it.windSpeed === windSpeed)?.safetyFactor || null;
  }

  getPropertyValue(property: string, windSpeed?: number) {
    if (property === 'safetyFactors') return windSpeed ? this.getSafetyFactor(windSpeed) : null;
    return this[property];
  }

  isUnsafe() {
    return (this.safetyFactorAt80Kmh ?? 0) < Tree.SAFETY_FACTOR_THRESHOLD;
  }

  getMaxWindSpeed() {
    return Math.max(...this.safetyFactors.map(it => it.windSpeed));
  }

  getMinWindSpeed() {
    return Math.min(...this.safetyFactors.map(it => it.windSpeed));
  }

  hasSafetyFactorAt(windSpeed: number) {
    return this.safetyFactors.some(it => it.windSpeed === windSpeed);
  }

  isSafeAt(windSpeed: number) {
    const safetyFactor = this.getSafetyFactor(windSpeed);
    if (safetyFactor === null) return null;
    return safetyFactor >= Tree.SAFETY_FACTOR_THRESHOLD;
  }

  testAgainst(property: DisplayableTreeProperty | null, threshold: number | null): ThresholdMatchingState {
    if (threshold === null || !this.hasProperty(property)) return ThresholdMatchingState.default();

    if (property === DisplayableTreeProperty.SafetyFactors) {
      if (!this.hasSafetyFactorAt(threshold)) return ThresholdMatchingState.default();
      if (this.isSafeAt(threshold)) return ThresholdMatchingState.matching();
      return ThresholdMatchingState.different();
    }

    if (this[property!]! >= threshold) return ThresholdMatchingState.matching();

    return ThresholdMatchingState.different();
  }

  isOutsideRange(property: DisplayableTreeProperty, from: number, to: number, isFirstRange: boolean) {
    if (isFirstRange) {
      return from > this[property] || to < this[property];
    }
    return from >= this[property] || to < this[property];
  }
}

export interface TreeDisplayConfiguration {
  property: DisplayableTreeProperty | null,
  managedAreaIds: string[],
  isManagedAreaSelectionReversed: boolean,
  filters: TreeFilter[],
  windSpeed: number
}

export interface TreeDto {
  id: string,
  managedAreaId: string,
  height: number,
  trunkHeight: number,
  trunkWidth: number,
  trunkEllipseRadiusA: number,
  trunkEllipseRadiusB: number,
  trunkCircumference: number,
  canopyHeight: number,
  canopyWidth: number,
  canopyEllipseRadiusA: number,
  canopyEllipseRadiusB: number,
  canopyCircumference: number,
  canopyDirection: number,
  canopyOffset: { coordinates: [number, number] },

  leafArea: number,
  leafBiomass: number,
  leafAreaIndex: number,
  carbonStorage: number,
  grossCarbonSequestration: number,
  no2: number,
  so2: number,
  pm25: number,
  co: number,
  o3: number,
  ndvi: number,
  treeHealth: number,
  carbonStorageEcoValue: number,
  grossCarbonSequestrationEcoValue: number,
  no2EcoValue: number,
  so2EcoValue: number,
  pm25EcoValue: number,
  coEcoValue: number,
  o3EcoValue: number,
  avoidedRunoffEcoValue: number,
  potentialEvapotranspiration: number,
  transpiration: number,
  oxygenProduction: number,

  safetyFactorAt80Kmh: number,
  safetyFactorAtDefaultWindSpeed: number,
  safetyFactors: SafetyFactor[],
  location: {
    coordinates: [Longitude, Latitude, number]
  },
  localizedLocation: {
    coordinates: [Longitude, Latitude, number]
  },
  externalId: string,
  customerTreeId: string,
  customerTagId: string,
  customerSiteId: string,
  images: TreeImage[],
  managedArea: ManagedAreaDto,
  trunkDiameter: number,
  genus: string,
  species: string,
  scientificName: string,
  pointCloudPath: string,
  environmentPointCloudPath: string,
  evaporation: number,
  waterIntercepted: number,
  avoidedRunoff: number,
  leaningVector: {
    coordinates: [number, number, number]
  } | null,
  leaningAngle: number,
  treeValueCavat: number,
  treeValueKoch: number,
  treeValueRado: number,
  thermalComfort: number,
  capturePointId: string,
  cohort: Cohort,
  dieback: number,
  deadBranchesRatio: number,
  viStatus: ViStatus,
  status: string,
  vitalityVigor: string,
  crownLightExposure: number,
  leafAreaPerCrownVolume: number,
  liveCrownRatio: number,
  slenderness: number,
  hasViObservation: boolean,
  hasMitigation: boolean,
  hasAssessmentRequest: boolean,
  mitigations: Mitigation[],
  cultivarOrVariety: string,
  cultivar: string,
  infraspecies: string,
  tmsCategory: string,
  ageClass: string,
  ageAtPlanting: number,
  includedBark: boolean | null,
  vat19: number,
  alnarpModel: number,
  normaGranada: number,
  limbs: { diameter: number }[],
  coDominantLimbs: boolean,
  fork: Fork,
  crossSectionalShape: CrossSectionalShape,
  foliageNoneSeasonal: boolean | null,
  foliageNoneDead: boolean | null,
  normalFoliage: number | null,
  chloroticFoliage: number | null,
  necroticFoliage: number | null,
  crownVolume: number | null,
  environment?: Partial<TreeEnvironment>,
  criticalRootZone: number | null,
  structuralCriticalRootZone: number | null,
  userUpdatedProperties: string[],
  outlierHeightPerCrownVolume: boolean | null,
  outlierHeightPerLeafArea: boolean | null,
  outlierLeafAreaPerCrownVolume: boolean | null,
  outlierTrunkDiameterPerCrownVolume: boolean | null,
  outlierTrunkDiameterPerHeight: boolean | null,
  outlierTrunkDiameterPerLeafArea: boolean | null,
  overallOutlierIndex: number | null,
  aggByClassWireClearances: any[] | undefined,
  lastUpdatedAt: string | null,
  wireClearanceIssueDetected: boolean,
  roadClearanceIssueDetected: boolean,
  buildingClearanceIssueDetected: boolean,
  visibilityClearanceIssueDetected: boolean,
  trafficSignClearanceIssueDetected: boolean,
  streetAddress: string,
  plantingYear: number,
  condition?: string,
  crownTransparency?: string,
  growSpace?: string,
  overheadUtilities?: string,
  prevailingWindDirection?: CardinalDirection,
  coDominantStems?: boolean,
  recordingDate?: string
}

export interface DisplayableViItem {
  name: string,
  residualRisk?: string
}

export interface Mitigation extends DisplayableViItem{
  taskTemplateId: string,
  taskTemplate: TaskTemplate,
  taskId: string
}

export interface SafetyFactor {
  safetyFactor: number,
  windDirection: number,
  windSpeed: number,
  weakestPoint: number
}
