import { useContext } from 'react';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DisplayableTreeProperty, Tree } from '../tree/Tree';
import { Flippers } from '../switches/Flippers';
import { useCurrentAccount } from '../account/useAccounts';
import { Organization } from '../organization/Organization';
import { StaticQueryKeys } from '../StaticQueryKeys';

const ACCOUNT_CACHE_TTL_MS = 1;
const CACHE_KEY = StaticQueryKeys.PROPERTY_CONFIGURATION;

const useCachedPropertyConfigurations = organizationId => {
  const { propertyConfigurationService } = useContext(DependencyInjectionContext);
  return useQuery(getPropertyConfigCacheKey(organizationId), () => propertyConfigurationService.list(organizationId), {
    staleTime: ACCOUNT_CACHE_TTL_MS,
    retry: 1
  });
};

export const getPropertyConfigCacheKey = organizationId => {
  return CACHE_KEY + '-' + organizationId;
};

export const usePropertyConfigurations = () => {
  const { organizationId } = useParams();
  const { data, isLoading, refetch } = useCachedPropertyConfigurations(organizationId);
  return { data: data || [], isLoading, refetch };
};

const daveyFilter = (property: DisplayableTreeProperty, organization: Organization) => {
  const hiddenPropertiesForDavey = [
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.ManagedAreaId,
    DisplayableTreeProperty.SafetyFactorAt80Kmh,
    DisplayableTreeProperty.SafetyFactors,
    DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed
  ];
  if (!organization.isEnabled(Flippers.davey)) return true;
  return !hiddenPropertiesForDavey.includes(property);
};

const getUnderConstructionFilter = (organization: Organization) => (property: DisplayableTreeProperty) => {
  const newProperties = [DisplayableTreeProperty.BuildingClearanceIssueDetected, DisplayableTreeProperty.VisibilityClearanceIssueDetected, DisplayableTreeProperty.TrafficSignClearanceIssueDetected];
  if (newProperties.includes(property)) return organization.isEnabled(Flippers.sprint77);
  return true;
};

export const useAvailableProperties = () => {
  const propertyConfigs = usePropertyConfigurations();
  const { organization } = useCurrentAccount();
  const underConstructionFilter = getUnderConstructionFilter(organization);

  const filterProperties = (property: DisplayableTreeProperty) => {
    const configs = propertyConfigs.isLoading ? [] : propertyConfigs.data!;
    return configs.some(it => it.property === property &&
      it.ranges.every(range => (!Number.isNaN(Number(range.from)) && !Number.isNaN(Number(range.to))) || range.value)
    );
  };

  return {
    metrical: Tree.METRICAL_PROPERTIES.filter(it => filterProperties(it) && daveyFilter(it, organization)).filter(underConstructionFilter),
    safety: Tree.SAFETY_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    ecosystem: Tree.ECOSYSTEM_PROPERTIES.filter(filterProperties).filter(underConstructionFilter),
    health: Tree.HEALTH_INDICATIONS.filter(filterProperties).filter(underConstructionFilter),
    economical: Tree.ECONOMICAL_VALUE.filter(filterProperties).filter(underConstructionFilter),
    identification: Tree.IDENTIFICATIONS.filter(filterProperties).filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    outlier: Tree.OUTLIER_PROPERTIES.filter(filterProperties).filter(underConstructionFilter),
    clearances: Tree.CLEARANCE_PROPERTIES.filter(filterProperties).filter(underConstructionFilter)
  };
};

export const useLegacyAvailableColumnSelectorProperties: (includeOutliers?: boolean) => LegacyAvailablePropertiesMap = (includeOutliers = false) => {
  const { organization } = useCurrentAccount();
  const underConstructionFilter = getUnderConstructionFilter(organization);

  return {
    metrical: Tree.METRICAL_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    safety: Tree.SAFETY_PROPERTIES.filter(underConstructionFilter),
    ecosystem: Tree.ECOSYSTEM_PROPERTIES.filter(underConstructionFilter),
    health: Tree.HEALTH_INDICATIONS.filter(underConstructionFilter),
    economical: Tree.ECONOMICAL_VALUE.filter(underConstructionFilter),
    identification: [
      ...Tree.IDENTIFICATIONS,
      // TODO: Move ManagedAreaId to Tree.IDENTIFICATIONS when deleting Flippers.workspace
      ...(organization.isEnabled(Flippers.workspace) ? [DisplayableTreeProperty.ManagedAreaId] : [])].filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    outlier: (includeOutliers ? Tree.OUTLIER_PROPERTIES : []).filter(underConstructionFilter),
    clearance: (organization.isEnabled(Flippers.wireClearance) ? Tree.CLEARANCE_PROPERTIES : []).filter(underConstructionFilter)
  };
};

export const useAvailableColumnSelectorProperties: () => AvailablePropertiesMap = () => {
  const { organization } = useCurrentAccount();
  const underConstructionFilter = getUnderConstructionFilter(organization);

  return {
    additionalInformation: Tree.INFO_PROPERTIES.filter(underConstructionFilter),
    assignmentAndTreeId: Tree.ASSIGNMENT_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    benefits: Tree.BENEFIT_PROPERTIES.filter(underConstructionFilter),
    clearances: Tree.CLEARANCE_PROPERTIES.filter(underConstructionFilter),
    conclusions: Tree.CONCLUSION_PROPERTIES.filter(underConstructionFilter),
    dates: Tree.DATES.filter(underConstructionFilter),
    dimensions: Tree.DIMENSION_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    treeHealthAndVitality: Tree.HEALTH_AND_VITALITY_PROPERTIES.filter(underConstructionFilter),
    loadFactors: Tree.LOAD_FACTOR_PROPERTIES.filter(underConstructionFilter),
    outliers: Tree.OUTLIER_PROPERTIES.filter(underConstructionFilter),
    siteFactors: Tree.SITE_FACTOR_PROPERTIES.filter(underConstructionFilter),
    structuralAssessment: Tree.STRUCTURAL_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    treeHealthAndSpeciesProfile: Tree.HEALTH_AND_SPECIES_PROPERTIES.filter(underConstructionFilter)
  };
};

export const useAvailableFilterItemProperties = () => {
  const { organization } = useCurrentAccount();
  const underConstructionFilter = getUnderConstructionFilter(organization);

  return {
    metricalInfo: Tree.METRICAL_PROPERTIES.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    safety: Tree.SAFETY_PROPERTIES.filter(it => it !== DisplayableTreeProperty.SafetyFactors).filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    ecosystemServices: Tree.ECOSYSTEM_PROPERTIES.filter(underConstructionFilter),
    healthIndication: Tree.HEALTH_INDICATIONS.filter(underConstructionFilter),
    economicalValue: Tree.ECONOMICAL_VALUE.filter(underConstructionFilter),
    inspections: [...Tree.INSPECTIONS].filter(underConstructionFilter),
    identification: Tree.IDENTIFICATIONS.filter(it => daveyFilter(it, organization)).filter(underConstructionFilter),
    outlierProperties: Tree.OUTLIER_PROPERTIES.filter(underConstructionFilter),
    clearance: (organization.isEnabled(Flippers.wireClearance) ? Tree.CLEARANCE_PROPERTIES : []).filter(underConstructionFilter)
  };
};

export const useTreeInfoProperties = () => {
  const { organization } = useCurrentAccount();

  const dimensionProperties = [
    DisplayableTreeProperty.Height,
    DisplayableTreeProperty.TrunkHeight,
    DisplayableTreeProperty.CanopyHeight,
    DisplayableTreeProperty.CanopyWidth,
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.TrunkDiameter,
    DisplayableTreeProperty.CanopyCircumference
  ].filter(it => daveyFilter(it, organization));

  const ecosystemProperties = [
    DisplayableTreeProperty.CarbonStorage,
    DisplayableTreeProperty.GrossCarbonSequestration,
    DisplayableTreeProperty.NO2,
    DisplayableTreeProperty.SO2,
    DisplayableTreeProperty.PM25,
    DisplayableTreeProperty.CO,
    DisplayableTreeProperty.O3,
    DisplayableTreeProperty.PotentialEvapotranspiration,
    DisplayableTreeProperty.Transpiration,
    DisplayableTreeProperty.OxygenProduction,
    DisplayableTreeProperty.AvoidedRunoff,
    DisplayableTreeProperty.Evaporation,
    DisplayableTreeProperty.WaterIntercepted
  ];

  const healthProperties = [
    DisplayableTreeProperty.LeafArea,
    DisplayableTreeProperty.LeafAreaIndex,
    DisplayableTreeProperty.NDVI,
    DisplayableTreeProperty.Dieback,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.LeafAreaPerCrownVolume,
    DisplayableTreeProperty.LiveCrownRatio
  ];

  const safetyProperties = [
    DisplayableTreeProperty.SafetyFactors,
    DisplayableTreeProperty.LeaningAngle,
    DisplayableTreeProperty.Slenderness
  ].filter(it => daveyFilter(it, organization));
  return {
    dimensionProperties, ecosystemProperties, healthProperties, safetyProperties
  };
};

export type LegacyAvailablePropertiesMap = {
  metrical: DisplayableTreeProperty[],
  safety: DisplayableTreeProperty[],
  ecosystem: DisplayableTreeProperty[],
  health: DisplayableTreeProperty[],
  economical: DisplayableTreeProperty[],
  identification: DisplayableTreeProperty[],
  outlier: DisplayableTreeProperty[]
};

export type AvailablePropertiesMap = {
  dimensions: DisplayableTreeProperty[],
  structuralAssessment: DisplayableTreeProperty[],
  benefits: DisplayableTreeProperty[],
  treeHealthAndSpeciesProfile: DisplayableTreeProperty[],
  assignmentAndTreeId: DisplayableTreeProperty[],
  clearances: DisplayableTreeProperty[],
  outliers: DisplayableTreeProperty[],
  conclusions: DisplayableTreeProperty[],
  loadFactors: DisplayableTreeProperty[],
  siteFactors: DisplayableTreeProperty[],
  treeHealthAndVitality: DisplayableTreeProperty[],
  additionalInformation: DisplayableTreeProperty[],
  dates: DisplayableTreeProperty[]
};
